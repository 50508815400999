/**
 * Announcements component.
 * @module components/Frontpage/Announcements
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Segment, List, Image } from 'semantic-ui-react';
import { keys, isEmpty } from 'lodash';

import { UniversalLink } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { FormattedMessage, injectIntl } from 'react-intl';

import { Carousel } from '../';

const getAnnouncementsPath = function (content) {
  let language = content.language.token
  let path = content['@id'] 

  if (language === 'ca')
    path = path + '/portada/avisos'
  if (language === 'en')
    path = path + '/home/notices'
  if (language === 'es')
    path = path + '/portada/avisos'

  return flattenToAppURL(path)
}


const SLIDE_OPTIONS = {
  loop: true,
  autoplay: true,
  buttons: true,
  customClass: 'announcements-slider',
  dots: false
}


const ItemView = props => {
  const {item} = props

  return (
    <p className="slider__announcement announcementItem announcements__item mb-0" key={item['@id']}> 
        {item?.getRemoteUrl ? (
          <UniversalLink
            className="item announcements__item__link"
            href={item.remoteUrl}>
            {item.title}
          </UniversalLink>
        ) : (
          <UniversalLink
            className="item announcements__item__link"
            href={item['@id']}>
            {item.title}
          </UniversalLink>
        )}
    </p>
  )

};

const Announcements = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const announcements = useSelector((state) => state.search.subrequests.announcements?.items);
  const announcementsPath = getAnnouncementsPath(content)

  React.useEffect(() => {
    dispatch(
      searchContent(
        announcementsPath,
        {
          portal_type: ['Announcement'],
          review_state: 'published',
          sort_on: 'getObjPositionInParent',
          b_size: 8,
          metadata_fields: ['remoteUrl', 'getRemoteUrl']
        },
        'announcements',
      ),
    );
  }, [dispatch]);

  return !isEmpty(announcements) ? (
    <Container>
      <Segment as="section" basic className="announcements my-5">
          <h2 className="announcements__title"><FormattedMessage id="Status" defaultMessage="Status"/></h2>
        <Carousel slides={announcements.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />
      </Segment>
    </Container>
  ) : (
    <></>
  );
};

export default injectIntl(Announcements);
