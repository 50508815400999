// **********************************************************
// CHANGE FONT SIZE
// export function fontSwitchSmall() {
// 	document.documentElement.style.fontSize = "12px";
// }

// export function fontSwitchRegular() {
// 	document.documentElement.style.fontSize = "16px";
// }

// export function fontSwitchBig() {
// 	document.documentElement.style.fontSize = "24px";
// }


// **********************************************************
// SHOW DIRECT ACTIONS ON MOBILE
export function toggleActions() { 
	var isSideBarOpen = document.getElementsByClassName('showSideBar');
	if (isSideBarOpen.length > 0) {
		// Actions sidebar is expanded, hide it
		document.getElementById("sidebarActions").classList.remove("showSideBar");
		document.getElementById("sidebarActionsToggler").setAttribute("aria-expanded", "false");
	} else {
		// Actions sidebar is no expanded, let it shine
		document.getElementById("sidebarActions").classList.add("showSideBar");
		document.getElementById("sidebarActionsToggler").setAttribute("aria-expanded", "true");
	}

}