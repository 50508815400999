/**
 * Header reducer.
 * @module reducers/header/header
 */

import { SET_HEADER_VISIBILITY, SET_HEADER_TITLE, SET_HEADER_DESCRIPTION, SET_HEADER_CONTENT } from '../../constants/ActionTypes';

const initialState = {
  showInnerHeader: true,
  title: undefined,
  description: undefined,
  content: undefined,
};

/**
 * Header reducer.
 * @function header
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function header(state = initialState, action = {}) {
  switch (action.type) {
    case SET_HEADER_VISIBILITY:
      return {
        ...state,
        showInnerHeader: action.value,
      }
    case SET_HEADER_TITLE:
      return {
        ...state,
        title: action.value,
      }
    case SET_HEADER_DESCRIPTION:
      return {
        ...state,
        description: action.value,
      }
    case SET_HEADER_CONTENT:
      return {
        ...state,
        content: action.value,
      }
    default:
      return state;
  }
}
