/**
 * Icon View component.
 * @module components/View/BlogIconView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Container as SemanticContainer, Grid, Card } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { keys, isEmpty, map, filter } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '../';


const BlogIconView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  const items = filter(content.items, (item) => {
    return item['@type'] !== 'Image' && !item.exclude_from_nav
  })

  const getViewURL = function (item) {
    let URL = flattenToAppURL(item['@id'])
    if (item['@type'] === 'File') {
      URL += '/@@display-file/file'
    }
    if (item['@type'] === 'Image') {
      URL += '/@@display-file/image'
    }
    if (item?.getRemoteUrl) {
      URL = item?.getRemoteUrl
    }
    return URL
  }

  return (
    <>
      <BodyClass className='blogIconView' />

      <Container id="page-home">
        <section id="content-core" className="blogIconView__page pt-5 pb-5">
          <Grid>
            {items.map((item) => (
              <Grid.Column mobile={12} tablet={6} computer={4} key={item['@id']}>
                <div className="iconItem">
                  <UniversalLink href={getViewURL(item)} className="iconLinkItem">
                    {item?.content_icon && (<div className="iconLinkItem__iconWrapper">
                      <Icon className="iconLinkItem__icon" name={item.content_icon} />
                    </div>)}
                    <strong className="iconLinkItem__text iconLinkItem__title ">{item.title}</strong>
                    {item.description && (<span className="iconLinkItem__text iconLinkItem__desc">{item.description}</span>)}
                  </UniversalLink>
                </div>
              </Grid.Column>
            ))}
          </Grid>
        </section>
      </Container>
    </>
  );
};

export default BlogIconView;
