import React from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { Segment, Header, List, Button, Label } from 'semantic-ui-react';
import { When, Recurrence } from '@plone/volto/components/theme/View/EventDatesInfo';
import { Icon, UniversalLink } from '@plone/volto/components';
import { expandToBackendURL, flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import { Map } from '../';
import { keys, isEmpty, map } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const messages = defineMessages({
	contactInfo: {
		id: 'event_contactinfo',
		defaultMessage: 'Contact info'
	},
  what: {
    id: 'event_what',
    defaultMessage: 'What',
  },
  when: {
    id: 'event_when',
    defaultMessage: 'When',
  },
  allDates: {
    id: 'event_alldates',
    defaultMessage: 'All dates',
  },
  downloadEvent: {
    id: 'Download Event',
    defaultMessage: 'Download Event',
  },
  where: {
    id: 'event_where',
    defaultMessage: 'Where',
  },
  location: {
    id: 'event_location',
    defaultMessage: 'Location',
  },
  contactName: {
    id: 'event_contactname',
    defaultMessage: 'Contact',
  },
  contactPhone: {
    id: 'event_contactphone',
    defaultMessage: 'Phone',
  },
  attendees: {
    id: 'event_attendees',
    defaultMessage: 'Attendees',
  },
  website: {
    id: 'event_website',
    defaultMessage: 'Website',
  },
  register_url: {
    id: 'event_register_url',
    defaultMessage: 'Register URL',
  },
  visitWebsite: {
    id: 'visit_external_website',
    defaultMessage: 'Visit external website',
  },
  contactEmail: {
    id: 'event_email',
    defaultMessage: 'Email',
  },
});


const RelatedInstrument = props => {
  const {item, instruments} = props
  const getRelatedInstrument = function(instrument) {
    if (!instruments) {
      return false
    }
    for (let item of instruments) {
      if (item.UID === instrument.token) return item
    }
    return false
  }
  const instrument = getRelatedInstrument(item)

  return instrument ? (
    <UniversalLink href={flattenToAppURL(instrument['@id'])}>{instrument.title}</UniversalLink>
  ) : (
    <>{item.title}</>
  );
};


const EventDetails = ({ content, display_as = 'aside' }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const getPosition = function (geolocation) {
    if (! geolocation ) {
      return null
    }
    return geolocation.split(';').map(parseFloat);
  }
  const geolocation = getPosition(content.geolocation)
  const lang = content.language.token;
  const relatedInstruments = useSelector((state) => state.search.subrequests.related_instruments?.items);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Instrument'],
      review_state: 'published',
      metadata_fields: ['UID']
    };

    dispatch(
      searchContent(
        '/',
        contentFilter,
        'related_instruments',
      ),
    );
  }, [dispatch]);

  return (
    <Segment className='eventInfo '
      as={display_as}
       
    >			 
			 	<h2 className="fs-4">
					{intl.formatMessage(messages.when)}
				</h2>
				<When
					start={content.start}
					end={content.end}
					whole_day={content.whole_day}
					open_end={content.open_end}
				/>
			 
      {content.recurrence && (
        <div className='eventInfo__field'>
          <Header sub>
            {intl.formatMessage(messages.allDates)}
          </Header>
          <Recurrence recurrence={content.recurrence} start={content.start} />
        </div>
      )}

			{(content?.contact_name || content?.contact_phone || content?.contact_email) ? 
				(<h2 className="fs-4">{intl.formatMessage(messages.contactInfo)}</h2>)
				: null }
			{content.contact_name && (
        <div className='eventInfo__field'>
          <Header sub>
            {intl.formatMessage(messages.contactName)}
          </Header>
          <p>{content.contact_name}</p>
        </div>
      )}
			{content.contact_email && (
        <div className='eventInfo__field'>
          <Header sub>
            {intl.formatMessage(messages.contactEmail)}
          </Header>
          <p><a href={`mailto:${content.contact_email}`}>{content.contact_email}</a></p>
        </div>
      )}
			{content.contact_phone && (
        <div className='eventInfo__field'>
          <Header sub>
            {intl.formatMessage(messages.contactPhone)}
          </Header>
          <p><a href={`phone:+34${content.contact_phone.replaceAll(' ', '')}` } >{content.contact_phone}</a></p>
        </div>
      )}
      {content.registration_url && (
        <div className="eventPage__register py-4">
          <Button
            primary
            as="a"
            rel="noopener noreferrer"
            href={content.registration_url}
            target="_blank" 
            className="eventPage__register__btn"
            >
              {intl.formatMessage(messages.register_url)}
            </Button>
        </div>
      )}


      {(content.event_location || content.location || content.geolocation) && (
				<>
        	<h2 className="fs-4 mb-2">{intl.formatMessage(messages.location)}</h2>          

          {content.location && (map(content.location.split(/\r?\n/), (item, index) => (
              <p key={index} className='mb-1'>{item}</p>
          )))}

          {content.event_location && (
            <>
            <h3 className="fs-5 mb-2 mt-3"><FormattedMessage id="Where" defaultMessage="Where"  /></h3>
            <p className="eventPage__location">
            {content.event_location.title}
            </p>
            </>
          )}

          {content.geolocation && (
            <Map marker={geolocation} center={geolocation} />
          )}

				</>
      )}
      

      {(false && content.subjects?.length) > 0 && (
				<>
					<Header sub>
						{intl.formatMessage(messages.what)}
					</Header>
					<List items={content.subjects} className="mt-1" />
				</>
      )}

			{content.attendees?.length > 0 && (
        <>
          <Header sub>
            {intl.formatMessage(messages.attendees)}
          </Header>
          <List items={content.attendees} />
        </>
      )}
      {content.event_url && (
        <>
          <Header sub>
            {intl.formatMessage(messages.website)}
          </Header>
          <p>
            <a
              href={content.event_url}
              target="_blank"
              rel="noopener noreferrer"
            >{content.event_url}
               
            </a>
          </p>
        </>
      )}

      {!isEmpty(content.related_instruments) && (
        <>
        <Header as="h2" className="newsPage__aside__title"><FormattedMessage id="Infrastructure" defaultMessage="Infrastructure" /></Header>
        <ul>
          {map(content.related_instruments, (item, index) => (
            <li key={index}>
              <RelatedInstrument item={item} instruments={relatedInstruments}></RelatedInstrument>
            </li>
          ))}
        </ul>
        </>
      )}

      <div className="download-event py-4">
				<Button
					primary
					as="a"
					rel="noreferrer"
          href={`${expandToBackendURL(content['@id'])}/ics_view`}
					target="_blank"	
					className="ics-download d-print-none"
					>
        		<Icon name={calendarSVG} className="mr-3 fs-6" />
          	{intl.formatMessage(messages.downloadEvent)}
					</Button>
      </div>
    </Segment>
  );
};

export default EventDetails;
