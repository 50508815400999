/**
 * Events component.
 * @module components/Frontpage/Events
 */

import React from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Segment, Container, Card, Grid } from 'semantic-ui-react';
import { keys, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Carousel } from '../';


const Events = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const events = useSelector((state) => state.search.subrequests.events?.items);
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const getEventsURL = function (content) {
    let language = content.language.token
    let path = content['@id']

    if (language === 'ca')
      path = content['@id'] + '/agenda'
    if (language === 'es')
      path = content['@id'] + '/agenda'
    if (language === 'en')
      path = content['@id'] + '/event'

    return flattenToAppURL(path)
  }
  const eventsURL = getEventsURL(content);

  const ItemView = (props) => {
    const { item } = props;

    return (
      <Card fluid as="figure" className={"eventItem " + (item?.hasPreviewImage ? 'eventItem-hasImage' : 'eventItem-noImage')} >

        <Image
          className="eventItem__img"
          item={item}
          loading="lazy"
          alt=""
        />

        <Card.Content as="figcaption" className="eventItem__text">
          <FormattedDate date={item.start} className="eventItem__date "
            format={{
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }}
          />
          {item?.location ? (
            <p className="eventItem__location mb-4">{item.location}</p>
          ) : null}

          <UniversalLink
            className="eventItem__link stretched-link"
            href={flattenToAppURL(item['@id'])}>
            {item.title}
          </UniversalLink>

        </Card.Content>
      </Card>
    );
  };

  const SLIDE_OPTIONS = {
    loop: false,
    customClass: 'related-events-slider',
    align: 'start',
    buttons: true,
    dots: false,
  };

  useEffect(() => {
    dispatch(
      searchContent(
        eventsURL,
        {
          portal_type: ['Event'],
          review_state: 'published',
          'end.query': moment().format('YYYY/MM/DD HH:MM'),
          'end.range': 'min',
          b_size: 6,
          metadata_fields: ['effective', 'location', 'start', 'hasPreviewImage']
        },
        'events',
      ),
    );
  }, [dispatch]);

  return !isEmpty(events) ? (
    <Segment as="section" basic id="industry-events" className="events industryEvents py-5 px-0">

      <Container className="py-3">
        <Header as="h2" size="huge" className="pt-3 mb-4 text-primary"><FormattedMessage id="Events" defaultMessage="Events" /></Header>

        <Carousel slides={events} ItemView={ItemView} options={SLIDE_OPTIONS} />

        <p className="text-center pt-4 mt-3">
          <UniversalLink href={eventsURL} className="ui primary button events__button">
            <FormattedMessage id="View more" defaultMessage="View more" /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
          </UniversalLink>
        </p>

      </Container>

    </Segment>
  ) : (
    <></>
  );
};

export default injectIntl(Events);