import React from 'react';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const ItemSchema = {
    title: 'Term-Entry',
    properties: {
        title: {
            title: 'Title',
        },
        link: {
            title: 'Link',
            widget: 'url',
        },
    },
    fieldsets: [
        {
            id: 'default',
            title: 'Term-Entry',
            fields: [
                'title',
                'link',
            ],
        },
    ],
    required: ['title', 'link'],
};

const LinkTermWidget = (props) => {
    return (
        <ObjectListWidget
            schema={ItemSchema}
            {...props}
            value={props.value?.items || props.default?.items || []}
            onChange={(id, value) => props.onChange(id, { items: value })}
        />
    );
};

export default LinkTermWidget;