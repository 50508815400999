import { defineMessages } from 'react-intl';

const messages = defineMessages({
  lastPublications: {
    id: 'Últimas publicaciones',
    defaultMessage: 'Últimas publicaciones',
  },
  filterPublications: {
    id: 'Filter publications',
    defaultMessage: 'Filter publications',
  },
  blCode: {
    id: 'Beamline',
    defaultMessage: 'Beamline',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
});

export const Schema = (props) => {
  return {
    title: props.intl.formatMessage(messages.lastPublications),
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['filter'],
      },
    ],
    properties: {
      filter: {
        title: props.intl.formatMessage(messages.blCode),
        choices: [
          ['All', 'All'],
          ['BL01', 'BL01'],
          ['BL04', 'BL04'],
          ['BL06', 'BL06'],
          ['BL09', 'BL09'],
          ['BL11', 'BL11'],
          ['BL13', 'BL13'],
          ['BL15', 'BL15'],
          ['BL16', 'BL16'],
          ['BL20', 'BL20'],
          ['BL22', 'BL22'],
          ['BL24', 'BL24'],
          ['BL25', 'BL25'],
          ['BL29', 'BL29'],
          ['BL31', 'BL31'],
          ['EM01', 'EM01'],
          ['EM02', 'EM02'],
          ['EM03', 'EM03'],
        ],
      },
    },
  };
};

export default Schema;
