/**
 * Instrument component.
 * @module components/Instrument/Instrument
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { Announcements, Slides, Menu, News } from './';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { setHeaderDescription } from '../../actions';


const Instrument = (props) => {
  const { content } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setHeaderDescription(undefined));
    };
  }, []);

  useEffect(() => {
    dispatch(setHeaderDescription(false));
  }, [dispatch]);

  return (
    <>
      <BodyClass className="no-bgArc no-mainFlex" /> 
 
      <Container>
        <Slides {...props} />
        <Menu {...props} />

        {content.description && (
          <p className="documentDescription pageDescription mt-5 maxContent mb-5 pb-3">{content.description}</p>
        )}
        <Announcements {...props} />

        {content.text && (<div className="mt-4 mb-5 maxContent">
          <div dangerouslySetInnerHTML={{ __html: content.text.data }} />
        </div>)}
            
        { ((content.introduction && content.introduction?.data !== '<p></p>') || content.apply_url) && (
        <Segment as="div" className="instrumentItem__intro" >
          {content.introduction && (
            <div className="instrumentItem__intro__text">
              <div dangerouslySetInnerHTML={{ __html: content.introduction.data }} />
            </div>)
          }

          {content.apply_url && (
            <UniversalLink className="ui button primary instrumentItem__intro__btn"
              href={content.apply_url}
              openLinkInNewTab={true}>

            <span className="instrumentItem__intro__btn__txt"><FormattedMessage id="Apply" defaultMessage="Apply"  /></span>
            <FontAwesomeIcon icon={faArrowRight} />

            </UniversalLink>
          )}
        </Segment>
        )}
      
      </Container>

      {hasBlocksData(content) ? (
        <Container className="userBlocks">
          <RenderBlocks {...props} />
        </Container>
      ): null}

      <News {...props} />

    </>
  );
};


export default Instrument;
