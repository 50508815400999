/**
 * Header info reducer.
 * @module reducers/header_info/header_info
 */

import { map } from 'lodash';
import {
  flattenToAppURL,
  getBaseUrl,
  hasApiExpander,
} from '@plone/volto/helpers';

import { GET_HEADER_INFO } from '../../constants/ActionTypes';
import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
  header_info: null
};

/**
 * Header info reducer.
 * @function header_info
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function header_info(state = initialState, action = {}) {
  let hasExpander;
  switch (action.type) {
    case `${GET_HEADER_INFO}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${GET_CONTENT}_SUCCESS`:
      hasExpander = hasApiExpander(
        'header_info',
        getBaseUrl(flattenToAppURL(action.result['@id'])),
      );
      if (hasExpander && !action.subrequest) {
        return {
          ...state,
          error: null,
          loaded: true,
          loading: false,
          header_info: action.result['@components'].header_info,
        };
      }
      return state;
    case `${GET_HEADER_INFO}_SUCCESS`:
      hasExpander = hasApiExpander(
        'header_info',
        getBaseUrl(flattenToAppURL(action.result['@id'])),
      );
      if (!hasExpander) {
        return {
          ...state,
          error: null,
          loaded: true,
          loading: false,
          header_info: action.result['@components'].header_info,
        };
      }
      return state;
    case `${GET_HEADER_INFO}_FAIL`:
      return {
        ...state,
        error: action.error,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
}
