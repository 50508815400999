/**
 * RelatedNews component.
 * @module components/News/RelatedNews
 */

import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card, Segment, Grid, Label, Header, Container } from 'semantic-ui-react';
import { defineMessages, useIntl, FormattedMessage,  } from 'react-intl';
import { searchContent } from '@plone/volto/actions';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keys, isEmpty, map, filter } from 'lodash';
import { Carousel, Sharing } from '../';
import { getVocabulary } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';


const SLIDE_OPTIONS = {
  loop: false,
  customClass: 'related-news-slider',
  align: 'start',
  buttons: true,
  dots: false,
}
const vocabularyNewsitemTypes = 'product.alba.vocabularies.NewsitemTypes';

const ItemView = props => {
  const {item} = props
  const itemsNewsitemTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyNewsitemTypes] && state.vocabularies[vocabularyNewsitemTypes].items
        ? state.vocabularies[vocabularyNewsitemTypes].items
        : [],
    shallowEqual,
  );

  const getNewsItemType = function(typeN) {
    if (!itemsNewsitemTypes) {
      return typeN
    }
    for (let item of itemsNewsitemTypes) {
      if (item.value === typeN) return item.label
    }
    return typeN
  }

  return (
    <Card fluid key={item['@id']}  as="figure" className={'newsItem newsItem-withShare ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')} >
      <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])} />
        <div className="ui image newsItem__img__wrapper">
          <Image 
            className="newsItem__img"
            item={item}
            loading='lazy'
            alt=""
            sizes="(max-width:575px) 100vw, 24rem"
          />
          {item?.newsitem_types ? (
            <Label.Group className="subjects newsItem__type">
              {map(item.newsitem_types, (item, index) => (
                <Label key={index}>{getNewsItemType(item)}</Label>
              ))}
            </Label.Group>
          ) : null}
        </div>

        <Card.Content as="figcaption" className="newsItem__text">                            

          <Card.Meta>
            <FormattedDate date={item.effective} className="newsItem__date "
              format={{
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }}
            />
          </Card.Meta>

          <Card.Header>
            <UniversalLink
              className="newsItem__link newsItem__title stretched-link" 
              href={flattenToAppURL(item['@id'])}>
              {item.title}
            </UniversalLink>
          </Card.Header>

        </Card.Content>

    </Card>
  )
};


const RelatedNews = props => {
  const content = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const relatedNews = useSelector((state) => {
    return filter(state.search.subrequests.related_news?.items, (item) => item.UID !== content.UID)
  });

  useEffect(() => {
    dispatch(
      searchContent(
        '/' + content.language.token,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          sort_on: 'effective',
          sort_order: 'reverse',
          newsitem_types: map(content.newsitem_types, (item) => item.token),
          b_size: 9,
          metadata_fields: ['effective', 'newsitem_types', 'hasPreviewImage', 'UID']
        },
        'related_news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyNewsitemTypes, lang: content.language.token }));
  }, [dispatch]);

  return !isEmpty(relatedNews) ? (
    <>
      <BodyClass className='hasRelatedContent'/>
      <Segment as="section" basic id="relatedNews" className="relatedNews">
        <div className="py-5">
          <Container>
            <Header as="h2" size="huge" className="relatedNews__title" ><FormattedMessage id="Related News" defaultMessage="Related News"/></Header>
            <Carousel slides={relatedNews.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />        
          </Container>
        </div>

      </Segment>
    </>
  ) : (
    <></>
  );
};

export default RelatedNews;