import { 
  EventsListing,
  Frontpage,
  InfographicView,
  Instrument,
  Industry,
  NewsListing,
  PublicationsListing,
  Landing,
  LandingJobs,
  Announcement,
  BlogView,
  BlogIconView,
  BeamlinesView,
  TendersView,
  // Blocks
  IconEdit,
  IconView,
  BannerListingView,
  BannerListingEdit,
  LastPublicationsView,
  LastPublicationsEdit,
  ContactProfilesView,
  ContactProfilesEdit,
  TransparentBoxesView,
  TransparentBoxesEdit,
  TimeLineView,
  TimeLineEdit,
  ImageView,
  ImageEdit,
  HeroImageLeftView,
  HeroImageLeftEdit,
  JobOffersView,
  JobOffersEdit,
  // Widgets
  IconSelectorWidget,
  ResultRenderer,
  LeafletWidget,
  MultilingualTermWidget,
  LinkTermWidget,
  AttachedImageWidget,
  // Icons
  albaIconsList,
} from './components';

import {
  defaultPanelConfig
} from './config';
import { GET_CONTENT } from '@plone/volto/constants/ActionTypes';
import { getImageBlockSizes } from '@plone/volto/components/manage/Blocks/Image/utils';
import ImageSettingsSchema from '@plone/volto/components/manage/Blocks/Image/LayoutSchema';
import HeroImageLeftBlockSchema from '@plone/volto/components/manage/Blocks/HeroImageLeft/schema';
import BlockSettingsSchema from '@plone/volto/components/manage/Blocks/Block/Schema';

import heroSVG from '@plone/volto/icons/hero.svg';
import UrlWidget from '@plone/volto/components/manage/Widgets/UrlWidget';  
import userSVG from '@plone/volto/icons/user.svg';
import commandSVG from '@plone/volto/icons/command.svg';
import calendarSVG from '@plone/volto/icons/calendar.svg';
import registrySVG from '@plone/volto/icons/registry.svg';
import dividerSVG from '@plone/volto/icons/cards.svg';
import cameraSVG from '@plone/volto/icons/camera.svg';
import spySVG from '@plone/volto/icons/spy.svg';

import reducers from './reducers';

const applyConfig = (config) => {
  // Mutilingual
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['en', 'es', 'ca'];
  config.settings.defaultLanguage = 'en';

  // Navigation
  config.settings.navDepth = 2;

  // Opengraph image
  config.settings.contentMetadataTagsImageField = 'preview_image';

  // Hide tags
  config.settings.showTags = false;

  // Enable Working Copy Support
  config.settings.hasWorkingCopySupport = true;

  //Links
  config.settings.openLinkInNewTab = true;

  // Cookie Consent
  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: defaultPanelConfig,
  };

  // Map
  config.settings.mapCenter = [41.4861363, 2.1080956]

  // Views
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      LRF: Frontpage,
      Instrument: Instrument,
      Industry: Industry,
      Landing: Landing,
      Announcement: Announcement,
      Tenders: TendersView,
    },
    layoutViews: {
      ...config.views.layoutViews,
      newslisting_view: NewsListing,
      eventslisting_view: EventsListing,
      publicationslisting_view: PublicationsListing,
      infographic_view: InfographicView,
      blog_view: BlogView,
      blog_icon_view: BlogIconView,
      beamlines_view: BeamlinesView,
      landing_view: Landing,
      landing_jobs_view: LandingJobs,
      tenders_view: TendersView,
    },
    layoutViewsNamesMapping: {
      ...config.views.layoutViewsNamesMapping,
      newslisting_view: 'News listing',
      eventslisting_view: 'Events listing',
      publicationslisting_view: 'Publications listing',
      infographic_view: 'Infographic',
      blog_view: 'Blog',
      blog_icon_view: 'Blog Icon',
      beamlines_view: 'Beamlines',
      landing_view: 'Landing',
      landing_jobs_view: 'LandingJobs',
      tenders_view: 'Tenders',
    },
  };

  // Reducers
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  };

  // Allow to delete title block
  config.blocks.requiredBlocks = [];
  // Blocks
  // Icon
  config.blocks.blocksConfig.icon = {
    id: 'icon',
    title: 'Icon',
    icon: commandSVG,
    group: 'common',
    view: IconView,
    edit: IconEdit,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
    blockHasOwnFocusManagement: false,
  };
  // BannerListing
  config.blocks.blocksConfig.bannerListing = {
    id: 'bannerListing',
    title: 'Banner Listing',
    group: 'common',
    icon: dividerSVG,
    view: BannerListingView,
    edit: BannerListingEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.lastPublications = {
    id: 'lastPublications',
    title: 'Last Publications',
    group: 'common',
    icon: heroSVG,
    view: LastPublicationsView,
    edit: LastPublicationsEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.contactProfiles = {
    id: 'contactProfiles',
    title: 'Contact Profiles',
    group: 'common',
    icon: userSVG,
    view: ContactProfilesView,
    edit: ContactProfilesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    variations: [
      {
        id: 'default',
        isDefault: true,
        title: 'Default',
        template: ContactProfilesView,
      },
      {
        id: 'imageTop',
        title: 'Image Top',
      },
    ],
  };
  config.blocks.blocksConfig.transparentBoxes = {
    id: 'transparentBoxes',
    title: 'Transparent Boxes',
    group: 'common',
    icon: registrySVG,
    view: TransparentBoxesView,
    edit: TransparentBoxesEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.timeline_block = {
    id: 'timeline_block',
    title: 'Timeline',
    icon: calendarSVG,
    group: 'common',
    view: TimeLineView,
    edit: TimeLineEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.image = {
    id: 'image',
    title: 'Image',
    icon: cameraSVG,
    group: 'media',
    view: ImageView,
    edit: ImageEdit,
    schema: ImageSettingsSchema,
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    getSizes: getImageBlockSizes,
  };
  config.blocks.blocksConfig.hero = {
    id: 'hero',
    title: 'Hero',
    icon: heroSVG,
    group: 'common',
    view: HeroImageLeftView,
    edit: HeroImageLeftEdit,
    schema: BlockSettingsSchema,
    blockSchema: HeroImageLeftBlockSchema,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: true,
    sidebarTab: 1,
  };
  config.blocks.blocksConfig.joboffers = {
    id: 'joboffers',
    title: 'joboffers',
    icon: spySVG,
    group: 'common',
    view: JobOffersView,
    edit: JobOffersEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };

  // Allowed blocks in grid
  config.blocks.blocksConfig.gridBlock.allowedBlocks = ['image', 'listing', 'slate', 'teaser', 'icon'];
  config.blocks.blocksConfig.gridBlock.blocksConfig.icon = {
    ...config.blocks.blocksConfig.icon,
  };
  // Most used
  config.blocks.blocksConfig.columnsBlock.mostUsed = true;
  config.blocks.blocksConfig.accordion.mostUsed = true;
  config.blocks.blocksConfig.statistic_block.mostUsed = true;
  config.blocks.blocksConfig.slateTable.mostUsed = true;
  config.blocks.blocksConfig.html.mostUsed = true;
  config.blocks.blocksConfig.listing.mostUsed = false;
  config.blocks.blocksConfig.gridBlock.mostUsed = false;
  config.blocks.blocksConfig.form.mostUsed = false;

  // Widgets
  config.widgets.widget.icon_selector = IconSelectorWidget;
  config.settings.widget.icon_selector = {
    ...config.settings.widget.icon_selector,
    iconList: {
      ...config.settings.widget.icon_selector.iconList,
      alba: albaIconsList,
    },
    defaultRenderer: {
      ...config.settings.widget.icon_selector.defaultRenderer,
      ResultRenderer
    }
  }

  config.widgets.widget.multilingual_term_widget = MultilingualTermWidget;
  config.widgets.widget.link_term_widget = LinkTermWidget;
  config.widgets.widget.attachedimage = AttachedImageWidget;

  config.widgets = {
    ...config.widgets,
    id: {
      ...config.widgets.id,
      content_icon: IconSelectorWidget,
      apply_url: UrlWidget,
      geolocation: LeafletWidget
    },
  };

  config.settings.apiExpanders = [
    ...config.settings.apiExpanders,
    {
      match: '',
      GET_CONTENT: ['header_info'],
    },
  ];

  config.settings.nonContentRoutes = [...config.settings.nonContentRoutes, '/fallback_login'];

  return config;
};


export default applyConfig;
