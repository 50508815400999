import React from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { keys, isEmpty, map, filter } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { Icon} from '../';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


const NavItem = ({ item, lang, mobile }) => {
  const { settings } = config;

  const getSubItems = function () {
    let subItems = filter(item.items, (item) => !item.content_icon)
    let subIconItems = []
    if (isEmpty(subItems)) {
      subItems = filter(item.items, 'content_icon')
      subIconItems = [];
    } else {
      subIconItems = filter(item.items, 'content_icon')
    }
    return {subItems, subIconItems}
  }
  const {subItems, subIconItems} = getSubItems()

  if (isInternalURL(item.url) || item.url === '') {
    return (
      <>
      {(!isEmpty(item.items) && !mobile ) ? (
        <Dropdown 
          text={item.title} 
          inline 
          basic
          item
          >
          
          <Dropdown.Menu className="mnPrincipal__second">
            <Dropdown.Header >
              <UniversalLink
                href={ item?.getRemoteUrl ? item?.getRemoteUrl : item['@id']}
                className="item">
                {item.title} <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
              </UniversalLink>
            </Dropdown.Header>

            <div className="mnPrincipal__second__wrapper">
              <div className="mnPrincipal__second__list">
                {map(subItems, (item, index) => (
                  <Dropdown.Item key={index}>
                    <UniversalLink
                      href={ item?.getRemoteUrl ? item?.getRemoteUrl : item['@id']}
                      className="item">
                        {item?.content_icon ? (
                          <>
                          <Icon className="mnPrincipal__banners__item__icon" name={item.content_icon} />
                          <strong className="mnPrincipal__banners__item__text ">{item.title}</strong>   
                          </>
                        ) : (
                          <>{item.title}</>
                        )}
                      </UniversalLink>
                  </Dropdown.Item>
                ))}
              </div>

              {!isEmpty(subIconItems) && (
              <div className="mnPrincipal__banners">
                {map(subIconItems, (item, index) => (
                <Dropdown.Item key={index}>
                  <UniversalLink
                    href={ item?.getRemoteUrl ? item?.getRemoteUrl : item['@id']}
                    className="mnPrincipal__banners__item">
                    <Icon className="mnPrincipal__banners__item__icon" name={item.content_icon} />
                    <strong className="mnPrincipal__banners__item__text ">{item.title}</strong>                        
                  </UniversalLink>
                </Dropdown.Item>
                ))}
              </div>
              )}

            </div>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <NavLink
          to={item.url === '' ? '/' : item.url}
          key={item.url}
          className="item"
          activeClassName="active"
          exact={
            settings.isMultilingual
              ? item.url === `/${toBackendLang(lang)}`
              : item.url === ''
          }
        >
          {item.title}
        </NavLink>
      )}
      </>
    );
  } else {
    return (
      <a
        href={item.url}
        key={item.url}
        className="item"
        rel="noopener noreferrer"
        target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
      >
        {item.title}
      </a>
    );
  }
};

export default NavItem;
