import CustomIcon from "./CustomIcons"
import { customIcons } from "./CustomIcons"


const Icon = (props) => {
  const { name, size, className } = props;
  const formatedName = name ? name
    .split(' ')
    .map((separetedName) => `fa-${separetedName}`)
    .join(' ') : '';
  return (
    <>
      {customIcons.includes(name) ? (
        <CustomIcon {...props} className={`${className ? className : ''} ${size ? size : ''}`} />
      ) : (
        <span className={`fa-solid ${formatedName} ${className ? className : ''} ${size ? size : ''}`} aria-hidden="true"></span>
      )}
    </>
  );
};

export default Icon;
