/**
 * Header component.
 * @module components/Header/Header
 */

import { useState, useEffect } from 'react';
import { Segment, Grid, Button, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl, useIntl } from 'react-intl';
import { faUniversalAccess, faCircleHalfStroke, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { map } from 'lodash';
import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  Breadcrumbs,
  UniversalLink
} from '@plone/volto/components'; 
import { getBaseUrl, hasApiExpander, addAppURL } from '@plone/volto/helpers';
import { toggleActions } from './custom.js';
import { HeaderContentInfo } from '../';
import { SearchWidgetModal } from '../';
import { BodyClass, flattenToAppURL } from '@plone/volto/helpers';
import { getHeaderInfo } from '../../actions';


const Header = ({ pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data);
  const { topActions = [] } = useSelector(
    (state) => ({
      topActions: state.actions?.actions?.top_actions,
    }),
    shallowEqual,
  );
  const showInnerHeader = useSelector((state) => state?.header?.showInnerHeader);
  const headerImage = useSelector((state) => state?.header_info?.header_info?.header_image);
  const [cssHeaderStyles, setCssHeaderStyles] = useState({}); 
  const url = content ? addAppURL(content?.['@id'] || '/') : null;
  const [darkMode, setDarkMode] = useState(false);
  const [fontSizeMode, setFontSizeMode] = useState('regular');

  const getContactURL = function (content) {
    let language = content?.language?.token
    let path = ''

    if (language === 'ca')
      path = '/' + language + '/que-es-alba/contacte'
    if (language === 'es')
      path = '/' + language + '/acerca-de/contacto'
    if (language === 'en')
      path = '/' + language + '/about/contact'

    return flattenToAppURL(path)
  }
  const contactURL = getContactURL(content)

  const handleContrastClick = () => {
    setDarkMode(!darkMode);
  };

  const fontSwitchSmall = () => {
    document.documentElement.style.fontSize = "12px";
    setFontSizeMode('small');
  };

  const fontSwitchRegular = () => {
    document.documentElement.style.fontSize = null;
    setFontSizeMode('regular');
  };

  const fontSwitchBig = () => {
    document.documentElement.style.fontSize = "24px";
    setFontSizeMode('big');
  };

  useEffect(() => {
    if (!hasApiExpander('header_info', getBaseUrl(pathname))) {
      dispatch(getHeaderInfo(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!headerImage?.['@id']) {
      return
    }
    setCssHeaderStyles({
      '--sa-bgHeader-xs': `url(${headerImage['@id']}/@@images/${headerImage.image_field}/large)`,
      '--sa-bgHeader-md': `url(${headerImage['@id']}/@@images/${headerImage.image_field}/larger)`,
      '--sa-bgHeader-lg': `url(${headerImage['@id']}/@@images/${headerImage.image_field}/huge)`
    })
  }, [headerImage])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedDarkMode = JSON.parse(localStorage.getItem('darkMode'), false);
      const storedFontSize = JSON.parse(localStorage.getItem('fontSizeMode'), false);
      setDarkMode(storedDarkMode)
      setFontSizeMode(storedFontSize)
    }

    // **********************************************************
    // Change header when the user scrolls the page
    var scrollDistance = function (callback, refresh) {
      // Make sure a valid callback was provided
      if (!callback || typeof callback !== 'function') return;
      // Variables
      var isScrolling, start, end, distance;
      start = 0;
      // Listen for scroll events
      window.addEventListener('scroll', function (event) {         
        // Clear our timeout throughout the scroll
        window.clearTimeout(isScrolling);
        // Set a timeout to run after scrolling ends
        isScrolling = setTimeout(function() {
          // Calculate distance
          end = window.scrollY;
          distance = end - start;
          // Run the callback
          callback(distance, start, end);
          // Reset calculations
          end = null;
          distance = null;
        }, refresh || 66);
      }, false);

    };
    // Add affix class on page load if necessary
    window.addEventListener('load', function(event) {
      if ( window.scrollY > (theHeader.offsetHeight + 16) ) {
        document.getElementById('portal-header').classList.add('affix');
      } else {
        document.getElementById('portal-header').classList.remove('affix');       
      }
    }); 
    var theHeader = document.getElementById('portal-header');
    scrollDistance(function (distance) {
      if ( distance > (theHeader.offsetHeight + 16) ) {
        document.getElementById('portal-header').classList.add('affix');
      } else {
        document.getElementById('portal-header').classList.remove('affix');       
      }
    });
      
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('darkMode', darkMode);
      localStorage.setItem('fontSizeMode', JSON.stringify(fontSizeMode) );
    }

    if (darkMode) {
      document.body.classList.add('highContrast');
    } else {
      document.body.classList.remove('highContrast');
    }

    if (fontSizeMode == 'small' ) {
      document.documentElement.style.fontSize = "12px";
    }
    if (fontSizeMode == 'regular' ) {
      document.documentElement.style.fontSize = null;
    }
    if (fontSizeMode == 'big' ) {
      document.documentElement.style.fontSize = "24px";
    }
  }, [darkMode, fontSizeMode]);

  return (
    <>
      <BodyClass className={ darkMode ? 'highContrast' : 'noContrast'} />  
      <BodyClass className={ ( fontSizeMode == 'small' ) ? 'fontSmall' : ''} />  
      <BodyClass className={ ( fontSizeMode == 'regular' ) ? 'fontRegular' : ''} />  
      <BodyClass className={ ( fontSizeMode == 'big' ) ? 'fontBig' : ''} />  

      <Segment basic as="header"  
        id="portal-header"  
        className="portalHeader text-white" 
        data-spy="affix" 
        data-offset-top="205" 
        >
        <Grid columns={2} >
          <Grid.Row className="portalHeader__top  d-print-none  border-bottom border-1 border-white py-2" >
            <Grid.Column className="headerCols1">
              <p className="fs-sm mb-0"><strong><FormattedMessage id="ALBA Synchrotron" defaultMessage="ALBA Synchrotron"  /></strong></p>
            </Grid.Column>
            <Grid.Column className="d-none d-md-block headerCols2" >
              <ul className="list-inline d-flex justify-content-end my-0 lh-1  pe-3 ">
                {topActions?.length
                  ? map(topActions, (item, index) => (
                    <li className={index ? 'ms-3 ps-3 border-start': null} key={item.id}>
                      <a className="text-decoration-none" target="_blank" rel="noopener" href={item.url}>
                        {item?.title}
                      </a>
                    </li>
                  ))
                : null}
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={12}>
          <Grid.Row className="position-relative align-items-lg-center  d-print-none ">
            <Grid.Column  mobile={4} tablet={4} computer={3} largeScreen={2} widescreen={2} className="headerLogo__wrapper">
              
              <Logo />

            </Grid.Column>
            <Grid.Column mobile={2} tablet={4} computer={6} largeScreen={8} widescreen={8} className="mnPrincipal__wrapper">

              <Navigation pathname={pathname} />

            </Grid.Column>
            <Grid.Column className="d-flex align-items-center justify-content-end headerLang__wrapper"  mobile={6} tablet={4} computer={3} largeScreen={2} widescreen={2}>

              <LanguageSelector />
              
              <SearchWidgetModal />

              {!token && ( 
                <Anontools  />                   
              )}

            </Grid.Column>            
          </Grid.Row>
        </Grid>
        {/* Floating sidebar */}
 
      </Segment>

      <Segment as="aside" id="sidebarActions" className="directActions d-print-none" aria-label="Top actions">
          <ul className="list-unstyled my-0">
              <li className="directActions__item">
                  <span className="directActions__toggler">
                    <FontAwesomeIcon icon={faUniversalAccess} />
                  </span>
                  <div className="directActions__content">
                      <Button basic className="constrastToggle" onClick={handleContrastClick}>
                        <FontAwesomeIcon icon={faCircleHalfStroke} />
                        <span className="visually-hidden"><FormattedMessage id="Contrast" defaultMessage="Contrast"  /></span>
                      </Button>
                      <div  className="ms-2 border-start">
                        <Button.Group basic size='small'>
                          <Button onClick={fontSwitchSmall} className="border-0 fontSwitch fontSwitch--small fs-sm">A-</Button>
                          <Button onClick={fontSwitchRegular} className="border-0 fontSwitch fontSwitch--regular">A</Button>
                          <Button onClick={fontSwitchBig} className="border-0 fontSwitch fontSwitch--big fs-lg">A+</Button>
                        </Button.Group>
                      </div>                        
                  </div>
              </li>
              <li className="directActions__item">
                  <span className="directActions__toggler">
                  <svg className="svg-inline--fa" xmlns="http://www.w3.org/2000/svg" width="18.072" height="20" viewBox="0 0 18.072 20" focusable="false" aria-hidden="true" role="img">
                    <path d="M18.06,16.137a2.924,2.924,0,0,0-1.968.773L8.934,12.743a3.287,3.287,0,0,0,.09-.7,3.287,3.287,0,0,0-.09-.7l7.078-4.127a3.005,3.005,0,1,0-.964-2.2,3.287,3.287,0,0,0,.09.7L8.06,9.841a3.012,3.012,0,1,0,0,4.4l7.149,4.177a2.832,2.832,0,0,0-.08.653,2.932,2.932,0,1,0,2.932-2.932Zm0-12.129a1,1,0,1,1-1,1A1.007,1.007,0,0,1,18.06,4.008ZM6.012,13.044a1,1,0,1,1,1-1A1.007,1.007,0,0,1,6.012,13.044ZM18.06,20.092a1,1,0,1,1,1-1A1.007,1.007,0,0,1,18.06,20.092Z" transform="translate(-3 -2)" fill="currentColor"/>
                  </svg>

                  </span>
                  <div className="directActions__content ">
                      {url ? (
                        <Button.Group basic size='small'>
                          <Button as="a" href={'https://www.facebook.com/sharer/sharer.php?u=' + url} > 
                            <FontAwesomeIcon icon={faFacebookF} className="fs-5" /><span className="visually-hidden">Facebook</span>
                          </Button>
                          <Button as="a" href={'https://twitter.com/share?url=' + url} >
                            <FontAwesomeIcon icon={faXTwitter} className="fs-5" /><span className="visually-hidden">Twitter</span>
                            </Button>
                          <Button as="a" href={'https://api.whatsapp.com/send?text= ' + url} >
                            <FontAwesomeIcon icon={faWhatsapp} className="fs-5" /><span className="visually-hidden">Whatsapp</span>
                          </Button>
                          <Button as="a" href={'http://www.linkedin.com/shareArticle?mini=true&amp;url=' + url} >
                            <FontAwesomeIcon icon={faLinkedin} className="fs-5" /><span className="visually-hidden">Linkedin</span>
                          </Button>
                        </Button.Group>
                      ): null}
                  </div>
              </li>
              <li className="directActions__item">
                  <span className="directActions__toggler">
                    <svg className="svg-inline--fa" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img" width="23.06" height="21" viewBox="0 0 23.06 21">
                      <path d="M13.718,12.956H27.279l-7.408,5.817a.991.991,0,0,1-.513.2,1.009,1.009,0,0,1-.517-.2l-5.294-4.159a.566.566,0,0,0-.189-.1,2.066,2.066,0,0,0,.384-1.235,2.968,2.968,0,0,0-.024-.323ZM10.269,9.214a.516.516,0,0,1,.106.042,1.892,1.892,0,0,1,.5.41,6.6,6.6,0,0,1,.951,1.447c.006.012.012.025.018.039a4.967,4.967,0,0,1,.8,2.142.832.832,0,0,1-.506.882l-1.006.58a2.206,2.206,0,0,0-1.042,1.684,2.79,2.79,0,0,0,.317,1.729l2.483,4.28a2.82,2.82,0,0,0,1.349,1.139,2.225,2.225,0,0,0,1.986-.056l1.006-.579a.844.844,0,0,1,1.021.007,4.989,4.989,0,0,1,1.466,1.761c.008.014.015.026.024.04a6.627,6.627,0,0,1,.784,1.541,1.878,1.878,0,0,1,.107.635c-.012.133-.015.119-.05.14l-.766.439a5.862,5.862,0,0,1-5.261-.171A9.894,9.894,0,0,1,10.656,23.3s0-.008-.007-.01l-2.095-3.61a.056.056,0,0,1-.008-.011,9.826,9.826,0,0,1-1.565-5.387A5.786,5.786,0,0,1,9.463,9.668l.766-.437c.018-.01.023-.018.04-.016Zm.008-1.076a1.142,1.142,0,0,0-.6.147l-.81.466-.051.032A6.972,6.972,0,0,0,5.887,14.24a10.846,10.846,0,0,0,1.723,6l2.088,3.6,0-.011a10.9,10.9,0,0,0,4.361,4.483,7.048,7.048,0,0,0,6.217.2.415.415,0,0,0,.053-.027l.811-.466a1.265,1.265,0,0,0,.594-.991,2.682,2.682,0,0,0-.154-1.06,7.238,7.238,0,0,0-.917-1.808,5.744,5.744,0,0,0-1.838-2.136,2.1,2.1,0,0,0-2.133-.016l-1.006.58a1.1,1.1,0,0,1-1.01,0,1.931,1.931,0,0,1-.826-.681l-2.483-4.28a1.92,1.92,0,0,1-.18-1.055,1.086,1.086,0,0,1,.5-.868l.977-.562a.541.541,0,0,0,.207.33l5.294,4.16a2.079,2.079,0,0,0,1.163.445.578.578,0,0,0,.063,0,2.077,2.077,0,0,0,1.162-.445L27.843,13.9v9.463c0,.144-.164.334-.486.334H22.309a.545.545,0,1,0,0,1.09h5.048a1.514,1.514,0,0,0,1.583-1.424V13.289a1.514,1.514,0,0,0-1.583-1.424H13.417a9.715,9.715,0,0,0-.615-1.24,7.261,7.261,0,0,0-1.118-1.693,2.7,2.7,0,0,0-.844-.663h0A1.406,1.406,0,0,0,10.277,8.139Z" transform="translate(-5.88 -8.138)" fill="currentColor"/>
                    </svg>
                  </span>
                  <div className="directActions__content">
                    <UniversalLink 
                      href={contactURL} 
                      className="text-decoration-none fw-bold">
                        <FormattedMessage id="Contact" defaultMessage="Contact"  />
                    </UniversalLink>
                  </div>
              </li>
              <li className="directActions__item directActions__item-toggler ">
                <Button id="sidebarActionsToggler" className="directActions__toggler" aria-expanded="false" aria-controls="sidebarActions" onClick={toggleActions}>
                  <FontAwesomeIcon className="directActions__toggler__icon" icon={faChevronLeft} />
                  <span className="visually-hidden"><FormattedMessage id="Show Menu" defaultMessage="Show Menu"  /></span>
                </Button>
              </li>
          </ul>
      </Segment>
      
      <div className="innerHeader bgArc " 
        style={cssHeaderStyles}
      >
        { showInnerHeader && (
          <>
            <Breadcrumbs pathname={pathname} />
            <HeaderContentInfo />
          </>
        )}
      </div>
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};

export default injectIntl(Header);