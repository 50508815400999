import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addBox: {
    id: 'Add Box',
    defaultMessage: 'Add Box',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  titleBoxes: {
    id: 'Boxes',
    defaultMessage: 'Boxes',
  },
  descriptionBoxes: {
    id: 'Boxes description',
    defaultMessage: 'Boxes description',
  },
  textLink: {
    id: 'Text Link',
    defaultMessage: 'Text Link',
  },
});
const boxSchema = (props) => ({
  title: 'Box',
  addMessage: props.intl.formatMessage(messages.addBox),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'image', 'link', 'textLink'],
    },
  ],
  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'text',
    },
    image: {
      title: props.intl.formatMessage(messages.image),
      widget: 'attachedimage',
    },
    link: {
      title: props.intl.formatMessage(messages.link),
      widget: 'url',
    },
    textLink: {
      title: props.intl.formatMessage(messages.textLink),
      widget: 'text',
    },
  },
  required: [],
});
export const Schema = (props) => {
  return {
    title: 'Transparent Boxes',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['boxes'],
      },
    ],
    properties: {
      boxes: {
        title: props.intl.formatMessage(messages.titleBoxes),
        description: props.intl.formatMessage(messages.descriptionBoxes),
        schema: boxSchema(props),
        widget: 'object_list',
      },
    },
    required: ['boxes'],
  };
};
export default Schema;
