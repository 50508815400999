import { defineMessages } from 'react-intl';

const messages = defineMessages({
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  url: {
    id: 'URL',
    defaultMessage: 'URL',
  },
  variant: {
    id: 'Variant',
    defaultMessage: 'Variant',
  },
});

export const IconSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.icon),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['content_icon', 'title', 'description', 'url', 'variant', 'aditionalText', 'readMoreText'],
      },
    ],
    properties: {
      content_icon: {
        title: props.intl.formatMessage(messages.icon),
        widget: 'string',
      },
      title: {
        title: props.intl.formatMessage(messages.title),
        widget: 'string',
      },
      description: {
        title: props.intl.formatMessage(messages.description),
        widget: 'string',
      },
      url: {
        title: props.intl.formatMessage(messages.url),
        widget: 'url',
      },
      aditionalText: {
        title: 'Aditional Text',
        type: 'boolean',
      },
      readMoreText: {
        title: 'Read More Text',
        widget: 'string',
      },
      variant: {
        title: props.intl.formatMessage(messages.variant),
        choices: [
          ['variant-icon-01', 'Variant 1'],
          ['variant-icon-02', 'Variant 2'],
          ['variant-icon-03', 'Variant 3'],
        ],
      },
    },
    required: ['content_icon'],
  };
};
