import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Label, Grid } from 'semantic-ui-react';
import { getJobOffers } from '../../services/getJobOffers';
import { isEmpty } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Offers = ({ limit }) => {
  const [offers, setOffers] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    getJobOffers().then((items) => {
      if (mounted) {
        setOffers(items);
      }
    });
    return () => (mounted = false);
  }, []);

  const offersToRender = limit ? offers.slice(0, limit) : offers;

  return (
    <Grid columns={12} className="mb-5">
      {!isEmpty(offers) &&
        offersToRender.map((item) => (
          <Grid.Column mobile={12} tablet={6} desktop={4} largeScreen={4} widescreen={3} className="mb-4">
            <Card fluid as="article" className="jobOfferCard position-relative h-100" key={item['@id']}>
              <Card.Header as="h2" className="jobOfferCard__title">
                {/* <Button as={Link} to={"/:lang?/landing-jobs/"+ item.url_slug} component={<OfferDetail {...item}></OfferDetail>} className="ui primary button moreInfoButton">Mes informació</Button> */}
                <a
                  className="jobOfferCard__link  stretched-link"
                  href={'https://public.cells.es/jobs/#!/jobs/' + item.url_slug}
                >
                  {item.title}{' '}
                </a>
              </Card.Header>
              <Card.Content>
                <div
                  className="jobOfferCard__desc shorterDescription"
                  dangerouslySetInnerHTML={{ __html: item.function }}
                />
                <span className="squareMore" aria-hidden="true">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </Card.Content>
              <Card.Content extra as="ul" className="list-unstyled">
                <li className="jobOfferCard__meta mb-2 jobOfferCard__ref">
                  <Label as="span">
                    <span className='fw-light'>
                      <FormattedMessage id="Reference" defaultMessage="Reference" />:</span> <span className="text-primary">{item.reference}{' '}</span></Label>
                </li>
                <li className="jobOfferCard__meta mb-2 jobOfferCard__deadline">
                  <Label as="span">
                    <span className='fw-light'>
                      <FormattedMessage id="Deadline" defaultMessage="Deadline" />:</span> <span className="text-primary">{item.reference}{' '}: {item.deadline}{' '}</span></Label>
                </li>
                <li className="jobOfferCard__meta mb-2 jobOfferCard__deadline">
                  <Label as="span">
                    <span className='fw-light'>
                      <FormattedMessage id="Division" defaultMessage="Division" />:</span> <span className="text-primary">{' '}: {item.division_name}{' '}</span></Label>
                </li>
              </Card.Content>
            </Card>
            </Grid.Column>
        ))}
    </Grid>
  );
};

export default Offers;
