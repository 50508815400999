import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  link: {
    id: 'Link',
    defaultMessage: 'Link',
  },
  links: {
    id: 'Links',
    defaultMessage: 'Links',
  },
  textLink: {
    id: 'Text Link',
    defaultMessage: 'Text Link',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  backgroundImage: {
    id: 'Background Image',
    defaultMessage: 'Background Image',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
});

const linkSchema = (props) => ({
  title: 'Link',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'link'],
    },
  ],
  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    link: {
      title: props.intl.formatMessage(messages.link),
      widget: 'url',
    },
  },
  required: ['title', 'link'],
});

const itemSchema = (props) => ({
  title: 'Item',
  addMessage: props.intl.formatMessage(messages.addItem),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'description', 'icon', 'link', 'image', 'textLink', 'links'],
    },
  ],
  properties: {
    title: {
      title: props.intl.formatMessage(messages.title),
      widget: 'text',
    },
    description: {
      title: props.intl.formatMessage(messages.description),
      widget: 'text',
    },
    link: {
      title: props.intl.formatMessage(messages.link),
      widget: 'url',
    },
    textLink: {
      title: props.intl.formatMessage(messages.textLink),
      widget: 'text',
    },
    image: {
      title: props.intl.formatMessage(messages.image),
      widget: 'attachedimage',
    },
    icon: {
      title: props.intl.formatMessage(messages.icon),
      widget: 'icon_selector',
    },
    links: {
      title: props.intl.formatMessage(messages.links),
      description: 'Add Link',
      schema: linkSchema(props),
      widget: 'object_list',
    },
  },
  required: ['title'],
});

export const schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['items', 'backgroundImage'],
      },
    ],
    properties: {
      items: {
        title: 'Items',
        description: 'Add Items',
        schema: itemSchema(props),
        widget: 'object_list',
      },
      backgroundImage: {
        title: props.intl.formatMessage(messages.backgroundImage),
        widget: 'attachedimage',
      },
    },
  };
};

export default schema;