/**
 * News component.
 * @module components/Frontpage/News
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Header, Segment, Label, Card } from 'semantic-ui-react';
import { keys, isEmpty, map } from 'lodash';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { Carousel, Sharing } from '../';

const SLIDE_OPTIONS = {
  loop: false,
  customClass: 'news-slider',
  align: 'start',
  buttons: true,
  dots: false,
}
const vocabularyNewsitemTypes = 'product.alba.vocabularies.NewsitemTypes';


const ItemView = props => {
  const {item} = props
  const itemsNewsitemTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyNewsitemTypes] && state.vocabularies[vocabularyNewsitemTypes].items
        ? state.vocabularies[vocabularyNewsitemTypes].items
        : [],
    shallowEqual,
  );

  const getNewsItemType = function(typeN) {
    if (!itemsNewsitemTypes) {
      return typeN
    }
    for (let item of itemsNewsitemTypes) {
      if (item.value === typeN) return item.label
    }
    return typeN
  }

  return (
    <Card fluid key={item['@id']}  as="figure" className={'newsItem newsItem-withShare ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')} >

        <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])} />

        <div className="ui image newsItem__img__wrapper">
          <Image 
            className="newsItem__img"
            item={item}
            loading='lazy'
            alt=""
            sizes="(max-width:575px) 100vw, 24rem"
          />
          {item?.newsitem_types ? (
            <Label.Group className="subjects newsItem__type">
              {map(item.newsitem_types, (item, index) => (
                <Label key={index}>{getNewsItemType(item)}</Label>
              ))}
            </Label.Group>
          ) : null}
        </div>

        <Card.Content as="figcaption" className="newsItem__text">                            

          <Card.Meta>
            <FormattedDate date={item.effective} className="newsItem__date "
              format={{
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }}
            />
          </Card.Meta>

          <Card.Header>
            <UniversalLink
              className="newsItem__link newsItem__title stretched-link" 
              href={flattenToAppURL(item['@id'])}>
              {item.title}
            </UniversalLink>
          </Card.Header>

        </Card.Content>

    </Card>
  )
};

const News = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const getNewsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id

    if (language === 'ca')
      path = '/' + language + '/public/noticies'
    if (language === 'es')
      path = '/' + language + '/publico/noticias'
    if (language === 'en')
      path = '/' + language + '/public/news'

    return flattenToAppURL(path)
  }
  const newsURL = getNewsURL(content)

  useEffect(() => {
    dispatch(
      searchContent(
        newsURL,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          b_size: 8,
          sort_on: 'effective',
          sort_order: 'reverse',
          metadata_fields: ['effective', 'newsitem_types', 'hasPreviewImage']
        },
        'news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyNewsitemTypes, lang: content.language.token }));
  }, [dispatch]);

  return !isEmpty(news) ? (
      <Segment as="section" basic id="homeNews" className="news py-5 wrapperNewsEvents__news homeSectionLayout">
        <div className="secCol1 d-lg-flex flex-lg-column align-items-start">
          <Header as="h2" size="large" className=" mt-0 text-primary"><FormattedMessage id="News" defaultMessage="News"/></Header>
          <p className="ff-headings fw-bold text-primary"><FormattedMessage id="Subtitle news" defaultMessage="Subtitle news"  /></p>
          <UniversalLink href={newsURL} className="ui primary button mt-lg-auto mb-4 d-print-none">
            <FormattedMessage id="View more" defaultMessage="View more"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
          </UniversalLink>
        </div>

        <div className="secCol2">
          <Carousel slides={news.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />
        </div>
         
      </Segment>
  ) : (
    <></>
  );
};

export default News;
