/**
 * SearchWidgetModal view component.
 * @module components/SearchWidget/SearchWidgetModal
 */

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Modal, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import { getNavroot } from '@plone/volto/actions';
import { hasApiExpander, getBaseUrl, flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  searchModalBtn: {
    id: 'openSearchModalTitle',
    defaultMessage: 'Open search window',
  },
});

const SearchWidgetModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const history = useHistory();
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';

  const onChangeText = (event, { value }) => {
    setText(value);
  };
  const pathname = props.pathname;
  const onSubmit = (event) => {
    const path =
      pathname?.length > 0 ? `&path=${encodeURIComponent(pathname)}` : '';

    history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(text)}${path}`);

    setText('');
    setOpen(false)
    event.preventDefault(); 
  };

  useEffect(() => {
    if (!hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <Modal 
      closeIcon 
      closeOnDimmerClick
      open={open} 
      trigger={<Button className="btnSearchToggle ms-md-3" title={intl.formatMessage(messages.searchModalBtn)} ><FontAwesomeIcon icon={faMagnifyingGlass} /><span className="visually-hidden"><FormattedMessage id="openSearchModal" defaultMessage="Open search window" /></span></Button>} 
      onClose={() => setOpen(false)} 
      onOpen={() => setOpen(true)}
      className="modalSearch">
      <Modal.Content>
        <Form action={`${navroot?.navroot?.['@id']}/search`} onSubmit={onSubmit}>
          <label className="searchbox__label" htmlFor="SearchableTextId" >{intl.formatMessage(messages.search)}</label>
          <Form.Field className="searchbox">
            <Input
            autoFocus={true}
              focus
              onChange={onChangeText}
              name="SearchableText"
              value={text}
              transparent
              autoComplete="off"
              placeholder={intl.formatMessage(messages.searchSite)} 
              id="SearchableTextId"
              className="searchbox__input"
              />
            <button type="submit" className="searchbox__button"> 
              <FontAwesomeIcon icon={faMagnifyingGlass} className="searchbox__button__icon-glass" />
              <span className="searchbox__button__text visually-hidden">{intl.formatMessage(messages.search)}</span>
              <FontAwesomeIcon className="searchbox__button__icon-arrow d-none" icon={faArrowRightLong}  />
            </button>
          </Form.Field>
        </Form>
      </Modal.Content>                
    </Modal>
  );
};

export default SearchWidgetModal;