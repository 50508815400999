/**
 * LandingJobs component.
 * @module components/Landing/LandingJobs
 */

import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import Offers from './Offers';

const LandingJobs = (props) => {
  const { content } = props;

  return (
    <>
      <BodyClass className='landing'/>
      <Container className="jobOffers">
        <Offers />
        {hasBlocksData(content) ? (
          <div  className="userBlocks">
            <RenderBlocks {...props} />
          </div>
        ): null}
      </Container>
    </>
  );
};


export default LandingJobs;
