/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl  } from 'react-intl';
import PropTypes from 'prop-types';
import { Container as SemanticContainer, Grid, Button, Header } from 'semantic-ui-react';
import { hasBlocksData, flattenToAppURL, flattenHTMLToAppURL, BodyClass } from '@plone/volto/helpers';
import { FormattedDate, UniversalLink } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import config from '@plone/volto/registry'; 
import { keys, isEmpty, map } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { setHeaderDescription, setHeaderContent } from '../../actions';
import { RelatedNews } from './';
import { Attachments, PrintButton } from '../';


const RelatedInstrument = props => {
  const {item, instruments} = props
  const getRelatedInstrument = function(instrument) {
  	if (!instruments) {
  		return false
  	}
    for (let item of instruments) {
      if (item.UID === instrument.token) return item
    }
    return false
  }
  const instrument = getRelatedInstrument(item)

  return instrument ? (
		<UniversalLink href={flattenToAppURL(instrument['@id'])}>{instrument.title}</UniversalLink>
  ) : (
    <>{item.title}</>
  );
};


/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const NewsItemView = ({ content }) => {
	const lang = content.language.token;
  const Image = config.getComponent({ name: 'Image' }).component;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const dispatch = useDispatch();
  const relatedInstruments = useSelector((state) => state.search.subrequests.related_instruments?.items);

  const getEffectiveDate = function () {
    return (
    	<FormattedDate date={content.effective}
        format={{
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }}
      />
    )
  };

  const getViewAllButton = function () {
		return (
			<UniversalLink  className="ui button inverted ms-auto innerHeader__backButton mb-4 d-print-none "
				href={flattenToAppURL(content.parent['@id'])}>
		  	<FontAwesomeIcon icon={faArrowLeft} className="me-4" /> <FormattedMessage id="View all" defaultMessage="View all" />
			</UniversalLink>
		)
  }

  useEffect(() => {
    return () => {
      dispatch(setHeaderDescription(undefined));
      dispatch(setHeaderContent(undefined));
    };
  }, []);

  useEffect(() => {
    dispatch(setHeaderDescription(getEffectiveDate()));
    dispatch(setHeaderContent(getViewAllButton()));
  }, [dispatch]);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Instrument'],
      review_state: 'published',
      metadata_fields: ['UID']
    };

    dispatch(
      searchContent(
        '/',
        contentFilter,
        'related_instruments',
      ),
    );
  }, [dispatch]);

	return (
		<>
		<BodyClass className='headerNegativeBottom'/>
		<div className="newsPage">
			<Container className="view-wrapper">
				<Grid columns={12}>
					<Grid.Row>
						<Grid.Column mobile={12} computer={8} largeScreen={9}>
							{content.preview_image && (
								<figure className="newsPage__img" >
									<Image
										className="documentImage ui image"
										alt=""
										item={content}
										imageField="preview_image"
										responsive={true}
										sizes="100vw"
									/>
									{content.preview_caption && (
										<figcaption className='newsPage__img__caption'>{content.preview_caption}</figcaption>
									)}
								</figure>
							)}

							{content.description && (<p className="documentDescription pageDescription">{content.description}</p>)}							

							{hasBlocksData(content) && (
								<div className="pageBlocks  userBlocks">
								<RenderBlocks content={content} />
								</div>
							)}

						</Grid.Column>
						<Grid.Column mobile={12} computer={4} largeScreen={3}>
							{ ( !isEmpty(content.research_areas) || !isEmpty(content.related_instruments) || !isEmpty(content.newsitem_types) ) ? (
							<aside className="newsPage__aside">
								{!isEmpty(content.research_areas) && (
									<>
									<Header as="h2" className="newsPage__aside__title"><FormattedMessage id="Research Area" defaultMessage="Research Area"  /></Header>
									<ul>
										{map(content.research_areas, (item, index) => (
											<li key={index}>{item.title}</li>
										))}
									</ul>
									</>
								)}

								{!isEmpty(content.related_instruments) && (
									<>
									<Header as="h2" className="newsPage__aside__title"><FormattedMessage id="Infrastructure" defaultMessage="Infrastructure" /></Header>
									<ul>
										{map(content.related_instruments, (item, index) => (
											<li key={index}>
												<RelatedInstrument item={item} instruments={relatedInstruments}></RelatedInstrument>
											</li>
										))}
									</ul>
									</>
								)}

							{content.newsitem_location && (
								<>
									<Header as="h2" className="newsPage__aside__title"><FormattedMessage id="Location" defaultMessage="Location"  /></Header>
									<p className="">
										{content.newsitem_location.title}
									</p>
								</>
							)}

								{!isEmpty(content.newsitem_types) && (
									<>
									<Header as="h2" className="newsPage__aside__title"><FormattedMessage id="Type" defaultMessage="Type"/></Header>
										{map(content.newsitem_types, (item, index) => (
															<span className="ui label" key={index}>{item.title}</span>
										))}
									</>
								)}

							</aside>
							) : null }
							<PrintButton />
						</Grid.Column>
					</Grid.Row>
				</Grid>

			</Container>
			<Attachments {...content} />
			{!isEmpty(content.newsitem_types) && (<RelatedNews {...content} />)}
		</div>
		</>
	)
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(NewsItemView);
