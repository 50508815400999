import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Icon } from '../../';
import { FormattedMessage } from 'react-intl';


const IconView = (props) => {
  const { data, mode } = props;
  const variantClass = ' ' + (data?.variant ? data.variant : 'variant-icon-01')

  return data.content_icon ? (
    <div className={'iconItem' + variantClass}>
      {(data?.url && mode !== 'edit' && data?.variant !== 'variant-icon-03') && (
        <UniversalLink href={data.url} className="iconLinkItem">
          {data.content_icon && (<div className="iconLinkItem__iconWrapper">
            <Icon className="iconLinkItem__icon" name={data.content_icon} />
          </div>)}
          {data.title && (<strong className={"iconLinkItem__text iconLinkItem__title " + (data.description ? ' ' : ' iconLinkItem__title--nodesc')}>{data.title}</strong>)}
          {data.description && (<span className="iconLinkItem__text iconLinkItem__desc">{data.description}</span>)}
          {data.aditionalText ? (
            <span className="iconLinkItem__readmore">{data.readMoreText}
            </span>
          ) : (
            <></>
          )}
        </UniversalLink>
      )}

      {(!data.url || mode === 'edit') && data?.variant !== 'variant-icon-03' && (
        <div className={'iconLinkItem' + (!data.description ? ' iconLinkItem--noDesc ' : ' ')}>
          {data.content_icon && (<div className="iconLinkItem__iconWrapper">
            <Icon className="iconLinkItem__icon" name={data.content_icon} />
          </div>)}
          {data.title && (<strong className="iconLinkItem__text  iconLinkItem__title">{data.title}</strong>)}
          {data.description && (<span className="iconLinkItem__text iconLinkItem__desc">{data.description}</span>)}
        </div>
      )}

      {(data?.variant === 'variant-icon-03') && (
        <>
          { (data?.url && mode !== 'edit') ? (
            <UniversalLink href={data.url} className="vi03Item vi03Item--link">
              {data.content_icon && (
                <div className="vi03Item__iconWrapper">
                  <Icon className="vi03Item__icon" name={data.content_icon} />
                </div>
              )}
              <div className="vi03Item__text">
                {data.title && (<strong className="vi03Item__title ">{data.title}</strong>)}
                {data.description && (<span className="vi03Item__desc">{data.description}</span>)}
                {data.aditionalText && (<span className="vi03Item__readmore">{data.readMoreText}</span>)}
              </div>
          </UniversalLink>
          ) : (
            <figure className="vi03Item">
                {data.content_icon && (
                  <div className="vi03Item__iconWrapper">
                    <Icon className="vi03Item__icon" name={data.content_icon} />
                  </div>
                )}
                <figcaption className="vi03Item__text">
                  {data.title && (<strong className="vi03Item__title ">{data.title}</strong>)}
                  {data.description && (<span className="vi03Item__desc">{data.description}</span>)}
                  {data.aditionalText && (<span className="vi03Item__readmore">{data.readMoreText}</span>)}
                </figcaption>
            </figure>
          )}
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default IconView;