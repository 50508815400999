/**
 * Sharing component.
 * @module components/Sharing/Sharing
 */

import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Dropdown } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareNodes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { toPublicURL } from '@plone/volto/helpers';


const messages = defineMessages({
  share: {
    id: 'Share',
    defaultMessage: 'Share',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
});


const Sharing = (props) => {
  const { id, title, url } = props;
  const itemURL = toPublicURL(url)
  const intl = useIntl();

  return (
    <>
         
	<Dropdown
		icon='share-nodes'
		floating
		button
		className='icon socialShare'
		aria-label={intl.formatMessage(messages.share)}
	>
		<Dropdown.Menu>
			<Dropdown.Header  content={intl.formatMessage(messages.share)} />
			<Dropdown.Divider />
			<Dropdown.Item>
				<a className="socialShare__link socialShare__link-facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${itemURL}`} rel="noopener" target="_blank"
					>
					<div className="socialShare__link__icon">
						<FontAwesomeIcon icon={faFacebookF} />
					</div>
					<span className="socialShare__link__text">Facebook</span>
				</a>
			</Dropdown.Item>
			<Dropdown.Item>
				<a className="socialShare__link" href={`https://twitter.com/share?url=${itemURL}`} rel="noopener" target="_blank"
					>
					<div className="socialShare__link__icon">
						<FontAwesomeIcon  icon={faXTwitter} />
					</div>
					<span className="socialShare__link__text">Twitter</span>
				</a>
			</Dropdown.Item>
			<Dropdown.Item>
				<a className="socialShare__link" href={`https://api.whatsapp.com/send?text=${itemURL}`} rel="noopener" target="_blank"
					>
					<div className="socialShare__link__icon">
						<FontAwesomeIcon  icon={faWhatsapp} />
					</div>
					<span className="socialShare__link__text">Whatsapp</span> 
				</a>
			</Dropdown.Item>
			<Dropdown.Item>
				<a className="socialShare__link" href={`mailto:?subject=${title}&body=${itemURL}`} rel="noopener" target="_blank">					
					<div className="socialShare__link__icon">
						<FontAwesomeIcon  icon={faEnvelope} />
					</div>
					<span className="socialShare__link__text">{intl.formatMessage(messages.email)}</span> 
				</a>
			</Dropdown.Item>
		</Dropdown.Menu>
	</Dropdown>

            
    </>
  );
};


export default Sharing;
