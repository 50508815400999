export function getPublications() {
  return fetch('https://public.cells.es/duo-services/publications/', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((data) => data.json())
    .then((data) => {
      return data.sort((a, b) => b.idpub - a.idpub);
    })
    .catch((error) => console.warn(error));
}
