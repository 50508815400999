/**
 * HeaderContentInfo view component.
 * @module components/Header/HeaderContentInfo
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { UniversalLink } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';


const HeaderContentInfo = (props) => {
  const [titleHeader, setTitleHeader] = useState();
  const titleContent = useSelector((state) => state?.content?.data?.title);
  const titleCustom = useSelector((state) => state?.header?.title);
  const content = useSelector((state) => state?.content?.data);
  const customRoot = useSelector((state) => state?.navroot?.data?.custom_root);
  const [descriptionHeader, setDescriptionHeader] = useState();
  const descriptionContent = useSelector((state) => state?.content?.data?.description);
  const descriptionCustom = useSelector((state) => state?.header?.description);

  const contentCustom = useSelector((state) => state?.header?.content);

  useEffect(() => {
    setTitleHeader(titleCustom === undefined ? titleContent : titleCustom)
    setDescriptionHeader(descriptionCustom === undefined ? descriptionContent : descriptionCustom)
  }, [titleContent, titleCustom, descriptionContent, descriptionCustom]);

  // Contact and Next events buttons
  const getIndustryHeader = function () {
    let language = content?.language?.token
    let contactURL = customRoot['@id'] + '/contact'
    let eventURL = customRoot['@id'] + '/events'


    if (language === 'ca') {
      contactURL = customRoot['@id'] + '/contacte'
      eventURL = customRoot['@id'] + '/agenda'

    }
    if (language === 'es') {
      contactURL = customRoot['@id'] + '/contacto'
      eventURL = customRoot['@id'] + '/agenda'

    }

    return (
      <nav className='ms-auto mt-4 mb-2 d-print-none mt-md-0'>
        <UniversalLink className="ui button inverted  innerHeader__backButton  " href={contactURL}>
          <FontAwesomeIcon icon={faEnvelope} className='me-4' /><FormattedMessage id="Contact" defaultMessage="Contact" />
        </UniversalLink>
        <UniversalLink className="ui button inverted ms-4 innerHeader__backButton " href={eventURL}>
          <FormattedMessage id="Next Events" defaultMessage="Next Events" />
          <FontAwesomeIcon icon={faArrowRight} className='ms-4' />
        </UniversalLink>
      </nav>
    );
  };

  const getUsersHeader = function () {
    let language = content?.language?.token
    let usersURL = '/en/users/contact'
    let safetyURL = '/en/users/safety'
    if (language === 'ca') {
      usersURL = '/ca/usuaris/contacte'
      safetyURL = '/ca/usuaris/seguretat'
    }
    if (language === 'es') {
      usersURL = '/es/usuarios/contacto'
      safetyURL = '/es/usuarios/seguridad'
    }
    return (
      <nav className='ms-auto mt-4 mb-2 d-print-none mt-md-0'>
        <UniversalLink className="ui button inverted  innerHeader__backButton  " href={usersURL}>
          <FontAwesomeIcon icon={faEnvelope} className='me-4' /><FormattedMessage id="Contact" defaultMessage="Contact" />
        </UniversalLink>
        <UniversalLink className="ui button inverted ms-4 innerHeader__backButton " href={safetyURL}>
          <FormattedMessage id="Safety" defaultMessage="Safety" />
          <FontAwesomeIcon icon={faArrowRight} className='ms-4' />
        </UniversalLink>
      </nav>
    );
  };
  const industryHeader = customRoot?.['@type'] === 'Industry' ? getIndustryHeader() : undefined
  const usersHeader = ['/en/users', '/es/usuarios', '/ca/usuaris'].some(str => content?.['@id']?.includes(str)) ? getUsersHeader() : undefined;

  return (
    <>
      {(titleHeader || descriptionHeader) && (
        <Container className="d-flex flex-wrap align-items-end">
          <div className="innerHeader__text ">
            {titleHeader && (<h1 className="documentFirstHeading innerHeader__title">{titleHeader}</h1>)}
            {descriptionCustom !== undefined ? (
              <>{descriptionCustom && (descriptionCustom)}</>
            ) : (<p className="documentDescription innerHeader__description">{descriptionContent}</p>)}
          </div>
          {contentCustom !== undefined ? (
            <>{contentCustom}</>
          ) : (null)}
          {industryHeader !== undefined ? (
            <>{industryHeader}</>
          ) : (null)}
          {usersHeader !== undefined ? (
            <>{usersHeader}</>
          ) : (null)}
        </Container>
      )}
    </>
  );
};


export default HeaderContentInfo;
