import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useDispatch } from 'react-redux';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { Form, Grid, Table, Container } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Pagination } from '@plone/volto/components';
import { FormattedMessage } from 'react-intl';
import { Message } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { map } from 'lodash';
import { UniversalLink, FormattedDate } from '@plone/volto/components';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';


const messages = defineMessages({
  year: {
    id: 'year',
    defaultMessage: 'Year',
  },
  contractType: {
    id: 'contractType',
    defaultMessage: 'Type of contract',
  },
  awardProcedure: {
    id: 'awardProcedure',
    defaultMessage: 'Award procedure',
  },
  Search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  publicationDate: {
    id: 'publicationDate',
    defaultMessage: 'Publication Date',
  },
  years: {
    id: 'years',
    defaultMessage: 'Years',
  },
  defaultOptionYear: {
    id: 'defaultOptionYear',
    defaultMessage: 'All',
  },
  defaultOptionTenderProcessCodes: {
    id: 'defaultOptionTenderProcessCodes',
    defaultMessage: 'All',
  },
  defaultOptionTenderContractCode: {
    id: 'defaultOptionTenderContractCode',
    defaultMessage: 'All',
  },
  Proceeding: {
    id: 'Proceeding',
    defaultMessage: 'Proceeding',
  },
  Title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  formalizationDate: {
    id: 'formalizationDate',
    defaultMessage: 'Formalization Date',
  },
  linkToPlatform: {
    id: 'linkToPlatform',
    defaultMessage: 'Link to platform',
  },
  tenderState: {
    id: 'tenderState',
    defaultMessage: 'Tender State',
  },
  defaultOptionTenderState: {
    id: 'defaultOptionTenderState',
    defaultMessage: 'All',
  },
});

const TendersView = ({ content, props }) => {
  const vocabularyTenderStates = 'product.alba.vocabularies.TenderStates';
  const vocabularyTenderContractCodes = 'product.alba.vocabularies.TenderContractCodes';
  const vocabulatyTenderProcessCodes = 'product.alba.vocabularies.TenderProcessCodes';
  const dispatch = useDispatch();
  const tenders = useSelector((state) => state.search.subrequests.tenders?.items);
  const total = useSelector((state) => state.search.subrequests.tenders?.total);
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [year, setYear] = useState('');
  const [contractCode, setContractCode] = useState('');
  const [tenderState, setTenderState] = useState('');
  const [processCode, setProcessCode] = useState('');
  const [search, setSearch] = useState(0);

  const getTendersUrl = function (content) {
    return '';
  };
  const getYears = function (startYear) {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 1980;
    while (startYear < currentYear) {
      startYear += 1;
      years.push({
        label: startYear,
        value: startYear,
      });
    }
    years.reverse();
    years.unshift({
      label: intl.formatMessage(messages.defaultOptionYear),
      value: '',
    })
    return years;
  };

  const itemsTenderStates = useSelector(
    (state) =>
      state.vocabularies[vocabularyTenderStates] && state.vocabularies[vocabularyTenderStates].items
        ? [{ value: '', label: intl.formatMessage(messages.defaultOptionTenderState) }, ...state.vocabularies[vocabularyTenderStates].items]
        : [],
    shallowEqual,
  );
  const itemsContractCodes = useSelector(
    (state) =>
      state.vocabularies[vocabularyTenderContractCodes] && state.vocabularies[vocabularyTenderContractCodes].items
        ? [{ value: '', label: intl.formatMessage(messages.defaultOptionTenderContractCode) }, ...state.vocabularies[vocabularyTenderContractCodes].items]
        : [],
    shallowEqual,
  );
  const itemsTenderProcessCodes = useSelector(
    (state) =>
      state.vocabularies[vocabulatyTenderProcessCodes] && state.vocabularies[vocabulatyTenderProcessCodes].items
        ? [{ value: '', label: intl.formatMessage(messages.defaultOptionTenderProcessCodes) }, ...state.vocabularies[vocabulatyTenderProcessCodes].items]
        : [],
    shallowEqual,
  );

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyTenderStates, lang: content.language.token }));
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyTenderContractCodes, lang: content.language.token }));
    dispatch(getVocabulary({ vocabNameOrURL: vocabulatyTenderProcessCodes, lang: content.language.token }));
  }, [dispatch]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeyear = (event, data) => {
    event.preventDefault();
    setYear(data.value);
    setCurrentPage(0);
  };

  const onChangeContractCode = (event, data) => {
    event.preventDefault();
    setContractCode(data.value);
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  const onChangeTenderState = (event, data) => {
    event.preventDefault();
    setTenderState(data.value);
    setCurrentPage(0);
  };

  const onChangeProcessCode = (event, data) => {
    event.preventDefault();
    setProcessCode(data.value);
    setCurrentPage(0);
  };

  const getTypeOfContract = function (contractCode) {
    if (!itemsContractCodes) {
      return contractCode
    }
    for (let item of itemsContractCodes) {
      if (item.value === contractCode) return item.label
    }
    return contractCode
  }

  const getTenderState = function (tenderState) {
    if (!itemsTenderStates) {
      return tenderState
    }
    for (let item of itemsTenderStates) {
      if (item.value === tenderState.toString()) return item.label
    }
    return tenderState
  }

  function getYearStartAndEnd(year) {
    let startDate = new Date(year, 0, 1);
    let endDate = new Date(year, 11, 31);
    return {
        startDate: dayjs(startDate),
        endDate: dayjs(endDate)
    };
  }

  useEffect(() => {
    let contentFilter = {
      portal_type: ['Tender'],
      b_size: pageSize,
      b_start: currentPage * pageSize,
      review_state: 'published',
      sort_on: 'last_update',
      sort_order: 'reverse',
      'tender_contract_code:int': contractCode ? parseInt(contractCode): contractCode,
      'tender_state:int': tenderState ? parseInt(tenderState): tenderState,
      'tender_process_code:int': processCode ? parseInt(processCode): processCode,
      metadata_fields: ['resolved_date', 'estimated_publication_date', 'proceeding_number', 'tender_link', 'tender_state', 'tender_contract_code', 'tender_process_code','last_update'],
    };

    if (year) {
      const { startDate, endDate } = getYearStartAndEnd(year)
      contentFilter['estimated_publication_date.query'] = [startDate?.format('YYYY/MM/DD') + ' 00:00', endDate?.format('YYYY/MM/DD') + ' 23:59']
      contentFilter['estimated_publication_date.range'] = 'min:max'
    }

    dispatch(
      searchContent(
        getTendersUrl(content),
        contentFilter,
        'tenders'
      ),
    );
  }, [search, dispatch, year, contractCode, tenderState, processCode, currentPage, pageSize]);
  return (
    <>
      <Container  className="pb-4  d-print-none">
      <Grid columns={12} className="mb-5 mb-lg-0">
        <Grid.Column mobile={12} tablet={10} computer={12} className="mx-auto" >
          <Form className="searchList" onSubmit={onSubmit}>
            <Form.Group widths='equal'>
              <Form.Select
                id="selectYear"
                label={intl.formatMessage(messages.year)}
                value={year}
                fluid
                options={map(getYears(2000), (item, index) => {
                  return {
                    key: index,
                    value: item.value,
                    text: item.label,
                  };
                })}
                name="year"
                onChange={onChangeyear}
                placeholder={intl.formatMessage(messages.defaultOptionYear)}                
              />
              <Form.Select
                id="selectContractCode"
                label={intl.formatMessage(messages.contractType)}
                value={contractCode}
                fluid
                options={map(itemsContractCodes, (item, index) => {
                  return {
                    key: index,
                    value: item.value,
                    text: item.label,
                  };
                })}
                name="tender_state"
                onChange={onChangeContractCode}
                placeholder={intl.formatMessage(messages.defaultOptionTenderContractCode)}
              />
              <Form.Select
                id="selectTenderProcessCode"
                label={intl.formatMessage(messages.awardProcedure)}
                value={processCode}
                fluid
                options={map(itemsTenderProcessCodes, (item, index) => {
                  return {
                    key: index,
                    value: item.value,
                    text: item.label,
                  };
                })}
                name="tender_process_codes"
                onChange={onChangeProcessCode}
                placeholder={intl.formatMessage(messages.defaultOptionTenderProcessCodes)}
              />
                <Form.Select
                id="selectTenderState"
                label={intl.formatMessage(messages.tenderState)}
                value={tenderState}
                fluid
                options={map(itemsTenderStates, (item, index) => {
                  return {
                    key: index,
                    value: item.value,
                    text: item.label,
                  };
                })}
                name="tender_state"
                onChange={onChangeTenderState}
                placeholder={intl.formatMessage(messages.defaultOptionTenderState)}
              />
            </Form.Group>
            <Form.Button className="searchList__send searchList__btn" primary><FormattedMessage id="Search" defaultMessage="Search" /></Form.Button>
          </Form>
        </Grid.Column>
      </Grid>
        <>
          <Table celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white"><FormattedMessage id="publicationDate" defaultMessage="Publication Date" /></Table.HeaderCell>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white border-gray-500"><FormattedMessage id="Proceeding" defaultMessage="Proceeding" /></Table.HeaderCell>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white border-gray-500"><FormattedMessage id="Title" defaultMessage="Title" /></Table.HeaderCell>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white border-gray-500"><FormattedMessage id="formalizationDate" defaultMessage="Formalization Date" /></Table.HeaderCell>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white border-gray-500"><FormattedMessage id="tenderState" defaultMessage="Tender State" /></Table.HeaderCell>
                <Table.HeaderCell className="fs-sm fw-bold text-nowrap bg-primary text-white border-gray-500"><FormattedMessage id="linkToPlatform" defaultMessage="Link to platform" /></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {!isEmpty(tenders) ? (
              <Table.Body>
                {tenders.map((tender) => (
                  <Table.Row key={tender['@id']}>
                    <Table.Cell>{tender.estimated_publication_date && (
                      <FormattedDate date={tender.estimated_publication_date} className=""
                        format={{
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }} />
                      )}
                    </Table.Cell>
                    <Table.Cell>{tender.proceeding_number}</Table.Cell>
                    <Table.Cell>{tender.title}</Table.Cell>
                    <Table.Cell>{tender.resolved_date && (
                      <FormattedDate date={tender.resolved_date} className=""
                        format={{
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        }} />
                      )}
                    </Table.Cell>
                    <Table.Cell>{getTenderState(tender.tender_state)}</Table.Cell>
                    <Table.Cell>
                      <UniversalLink href={tender.tender_link} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="View Tender" defaultMessage="View Tender" /> <FontAwesomeIcon className='fs-sm' icon={faArrowUpRightFromSquare} />
                      </UniversalLink>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ) : (
              <Message className="results">
                <FormattedMessage id="No results found" defaultMessage="No results found" />
              </Message>
            )}
          </Table>
          {total > pageSize && (
            <nav className="pagination">
              <Pagination
                current={currentPage}
                total={Math.ceil(total / pageSize)}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </nav>
          )}
        </>
      </Container>
    </>
  );
};

export default TendersView;
