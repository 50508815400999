/**
 * Language selector component.
 * @module components/LanguageSelector/LanguageSelector
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { find, map } from 'lodash';
import {
  Helmet,
  langmap,
  flattenToAppURL,
  toReactIntlLang,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const messages = defineMessages({
  switchLanguageTo: {
    id: 'Switch to',
    defaultMessage: 'Switch to',
  },
  chooseLanguage: {
    id: 'Choose language',
    defineMessage: 'Choose your language'
  }
});

const LanguageSelector = (props) => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const translations = useSelector(
    (state) => state.content.data?.['@components']?.translations?.items,
  );
  const currentUrl = useSelector((state) => state.router.location.pathname);
  const { settings } = config;
  const toLink = (lang, translation) => {
    if (translation && currentUrl.includes('/contents'))
      return flattenToAppURL(translation['@id']) + '/contents';
    else
      return translation ? flattenToAppURL(translation['@id']) : `/${lang}`;
  };

  return (
    <>
      {settings.isMultilingual ? (
    	 <Dropdown className="langSelector"
        text={currentLang} 
        aria-label={intl.formatMessage(messages.chooseLanguage)}
        >  
    		<Dropdown.Menu as="ul">
    			{map(settings.supportedLanguages, (lang) => {
    					const translation = find(translations, { language: lang });
    					return (
    						<Dropdown.Item key={`language-selector-${lang}`} as="li">
    							<Link
    								aria-label={`${intl.formatMessage(
    									messages.switchLanguageTo,
    								)} ${langmap[lang].nativeName.toLowerCase()}`}
    								className={cx({ selected: toReactIntlLang(lang) === currentLang })}
    								to={toLink(lang, translation)} 
    								onClick={() => {
    									props.onClickAction();
    								}}
    								key={`language-selector-${lang}`}
                    hrefLang={lang}
                    aria-selected={toReactIntlLang(lang) === currentLang}
                  >
                    {langmap[lang].nativeName}
    							</Link>
    						</Dropdown.Item>
    					);
    				})}
    		</Dropdown.Menu>

    	 </Dropdown>
      ) : (
        <Helmet>
          <html lang={toReactIntlLang(settings.defaultLanguage)} />
        </Helmet>
      )}
    </>
  );
};

LanguageSelector.propTypes = {
  onClickAction: PropTypes.func,
};

LanguageSelector.defaultProps = {
  onClickAction: () => {},
};

export default LanguageSelector;
