import React from 'react';
import loadable from '@loadable/component';
const InnerMap = loadable(() => import('./InnerMap'), {ssr: false});

const Map = (props) => {

  return (
    <InnerMap {...props} />
  );
};

export default Map;