/**
 * Footer component.
 * @module components/Footer/Footer
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, Container, Grid, Image, List, Segment } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faXTwitter, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { map, isEmpty } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { UniversalLink, Image as VoltoImage} from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import LogoAlba from './img/logo.svg';
import LogoECityClic from './img/logo-ecityclic.svg';
import { useGoogleAnalytics } from '../../helpers/useGoogleAnalytics';
import {
  usePanelConfigAndPreferences,
  GDPRCookies,
  getLocaleConf, 
} from 'volto-gdpr-privacy/helpers';


const messages = defineMessages({
  footerNav: {
    id: 'Footer navigation',
    defaultMessage: 'Footer navigation',
  },
  albaSynchrotron: {
    id: 'Alba Synchrotron',
    defaultMessage: 'Alba Synchrotron',
  },
  gencatDru: {
    id: 'Generalitat de Catalunya. Departament de Recerca i Universitats',
    defaultMessage: 'Generalitat de Catalunya. Departament de Recerca i Universitats',
  },
  gobEsp: {
    id: 'Gobierno de España. Ministerio de Ciencia e Innovación',
    defaultMessage: 'Gobierno de España. Ministerio de Ciencia e Innovación',
  },
  ueFeder: {
    id: 'Unió Europea. Fons Europeu de Desenvolupament Regional',
    defaultMessage: 'Unió Europea. Fons Europeu de Desenvolupament Regional',
  }
});

const socialIcons = {
  facebook: faFacebookF,
  twitter: faXTwitter,
  youtube: faYoutube,
  instagram: faInstagram,
  linkedin: faLinkedin
}

const getBannersPath = function (content) {
  let language = content?.language?.token
  let path = '/' + language + '/home/banners'

  if (language === 'ca')
    path = '/' + language + '/portada/banners'
  if (language === 'es')
    path = '/' + language + '/portada/banners'

  return path
}


const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );
  const { socialActions = [] } = useSelector(
    (state) => ({
      socialActions: state.actions?.actions?.social_actions,
    }),
    shallowEqual,
  );
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const year = new Date().getFullYear();
  const content = useSelector((state) => state?.content?.data);
  const getNewsletterURL = function (content) {
    let language = content?.language?.token
    if (language === 'ca')
      return 'https://newsletter.cells.es/form/5'
    if (language === 'es')
      return 'https://newsletter.cells.es/form/7'
    return 'https://newsletter.cells.es/form/6'
  }
  const getHowToArriveURL = function (content) {
    let language = content?.language?.token
    let path = ''

    if (language === 'ca')
      path = '/' + language + '/que-es-alba/com-arribar-hi'
    if (language === 'es')
      path = '/' + language + '/acerca-de/como-llegar'
    if (language === 'en')
      path = '/' + language + '/about/how-to-reach-alba'

    return flattenToAppURL(path)
  }

  const newsletterURL = getNewsletterURL(content)
  const howToArriveURL = getHowToArriveURL(content)

  const dispatch = useDispatch();
  const banners = useSelector((state) => state.search.subrequests.banners?.items);
  const bannersPath = getBannersPath(content)

  const cookies = new GDPRCookies();
  const { defaultPreferences } = usePanelConfigAndPreferences(cookies);
  const gdprPreferences = useSelector(
    (state) => state.gdprPrivacyConsent.preferences ?? defaultPreferences,
  );

  useGoogleAnalytics(gdprPreferences?.GANALYTICS, token)

  useEffect(() => {
    dispatch(
      searchContent(
        bannersPath,
        {
          portal_type: ['Banner'],
          review_state: 'published',
          sort_on: 'getObjPositionInParent',
          b_size: 8,
          metadata_fields: ['remoteUrl']
        },
        'banners',
      ),
    );
  }, [dispatch]);

  return (
    <Segment basic as="footer" id="footer" className="portalFooter">

      {/* PATROS */}
      {!isEmpty(banners) && (
        <Segment secondary className="secPatros py-5">
          <Container className="d-flex flex-wrap justify-content-center align-items-center">
            {banners.map((item) => (
              <UniversalLink key={item['@id']} href={item.remoteUrl}  className="patros__link ui image">
                <VoltoImage
                  item={item}
                  alt={item.title}
                 
                />
              </UniversalLink>
            ))}
          </Container>
        </Segment>
      )}

      {/* FOOTER */}
      <Segment secondary className="portalFooter bg-primary text-white pt-5">
        <Container className="mb-lg-5">
          <Grid columns={12}>
            <Grid.Column mobile={12} tablet={6} computer={4} largeScreen={4} widescreen={4} className="footerColumn1">
              <figure className="logoFooter mx-auto">
                <Image
                    className="logoFooter__img mb-3 mx-auto"
                    width="123" height="80"
                    src={LogoAlba}
                    alt={intl.formatMessage(messages.albaSynchrotron)}
                  />
                <figcaption className=" text-center">
                  <FormattedMessage
                    id="Consortium for the Construction, Equipment and Operation of the Synchrotron Light Laboratory (CELLS)"
                    defaultMessage="Consortium for the Construction, Equipment and Operation of the Synchrotron Light Laboratory (CELLS)"
                  />
                </figcaption>
              </figure>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={6} computer={4} largeScreen={4} widescreen={4}  className="footerColumn2" >
              <p  className="fs-6 text-center"><FormattedMessage id="Footer description" defaultMessage="Footer description"  /></p>
              <p className="fs-sm text-center">Carrer de la Llum 2-26 08290 Cerdanyola del Vallès, Barcelona, <FormattedMessage id="Spain" defaultMessage="Spain"  /></p>
              <p className="fs-6 text-center mb-lg-0">
                <UniversalLink className="fs-sm fw-bold font-weight-bold d-block" href={howToArriveURL}>
                  <FormattedMessage id="How to arrive" defaultMessage="How to arrive"  />
                </UniversalLink>
                <a href="tel:+34935924300" className="fs-4 fw-bold text-decoration-none">+34 93 592 43 00</a>
              </p>
            </Grid.Column>
            <Grid.Column mobile={12} tablet={12} computer={4}  largeScreen={4} widescreen={4} className="footerColumn3 d-print-none">
              <p className="text-center mb-5 mb-lg-4">
                <strong className="d-block mb-3"><FormattedMessage id="Subscribe to our newsletter" defaultMessage="Subscribe to our newsletter"  /></strong>
                <UniversalLink className="ui inverted button" as='a' href={newsletterURL}>
                  <FormattedMessage id="Subscribe" defaultMessage="Subscribe"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-3 me-n1" />
                </UniversalLink>
              </p>
              <p className="footerXXSS border-top py-4 border-white border-opacity-50 mb-0 justify-content-center d-print-none d-flex mt-lg-auto border-top-lg-0 py-lg-0">
                {socialActions?.length
                  ? map(socialActions, (item) => (
                    <a className="mx-2" key={item.id} target="_blank" rel="noopener" href={item.url}>
                      <FontAwesomeIcon icon={socialIcons[item.id]} />
                      <span className="visually-hidden">{item?.title}</span>
                    </a>
                  ))
                : null}
              </p>
            </Grid.Column>
          </Grid>
        </Container>

        <div className="border-top border-white border-opacity-75 pt-4 fs-6">
          <Container className="py-lg-2 pt-4">
            <Grid columns={12} className="mb-0">
              <Grid.Row verticalAlign="middle">
                <Grid.Column mobile={12} computer={7} className="mb-5 mb-lg-0 order-lg-2" >
                  <nav aria-label={intl.formatMessage(messages.footerNav)} className="d-print-none">
                    <ul className="list-unstyled my-0 text-center d-lg-flex justify-content-lg-center">
                      {siteActions?.length
                        ? map(siteActions, (item) => (
                          <li key={item.id} className="mb-2 mb-lg-0 mx-lg-2 mx-xl-2">
                            <UniversalLink
                              className="text-decoration-none"
                              href={
                                item.url ? flattenToAppURL(item.url) : addAppURL(item.id)
                              }
                            >
                              {item?.title}
                            </UniversalLink>
                          </li>
                        ))
                      : null}
                    </ul>
                  </nav>
                </Grid.Column>
                <Grid.Column mobile={6} computer={2} className="order-lg-1 d-print-none" >
                  © {year} CELLS
                </Grid.Column>
                <Grid.Column mobile={6} computer={3} className="opacity-75 text-end order-lg-3 d-print-none">
                  <p className='fs-sm'>
                    <FormattedMessage id="Project developed by" defaultMessage="Project developed by"  />
                    <Image
                        className="ms-2 logo-ecityclic"
                        width="240" 
                        height="56" 
                        src={LogoECityClic}
                        alt="eCityclic."
                        href="https://www.ecityclic.com/"
                        target="_blank" 
                      />
                  </p>
                </Grid.Column>
               </Grid.Row>
            </Grid>
          </Container>
        </div>
      </Segment>
 

    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
