import React from 'react';
import { a } from 'react-spring';
import { Segment, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { UniversalLink, Image } from '@plone/volto/components';

const View = (props) => {
  const { data, mode } = props;

  const fullWidthClass = ' ' + (mode !== 'edit' ? 'full-width' : '');
  return (
    <>
      <div className={'transBox__wrapper ' + fullWidthClass} >
        {data.boxes ? (
          data.boxes?.map((item, id) => (
            <Segment as="article" basic className={ 'transBox ' + (item.image ? ' transBox--hasImage' : ' transBox--noImage')}
              key={id} 
            >
              {item?.image && (
                <img 
                  className="transBox__image"
                  src={item.image +'/@@images/image'}
                  loading='lazy'
                  alt=""
                  srcSet={item.image +'/@@images/image/preview 400w, ' + item.image +'/@@images/image/large 800w, ' + item.image +'/@@images/image/tablet 1024w, ' + item.image +'/@@images/image/huge 1600w'}
                  sizes="100vw"
                />
                )
              }
              <Header as="h2" className='transBox__title'>{item.title}</Header>
              {item?.description && (
                <p className='transBox__desc'>{item.description}</p>
              )}
              { item?.link && (
                <UniversalLink href={item.link} className="ui button inverted transBox__link">
                  {item?.textLink ? (
                    <>{item.textLink}</>
                  ) : (
                    <FormattedMessage id="View more" defaultMessage="View more"  />
                  )}
                </UniversalLink>
              )}             
            </Segment>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default View;
