/**
 * Events component.
 * @module components/Frontpage/Events
 */

import React from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, Segment, Card, Header } from 'semantic-ui-react';
import { keys, isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { Carousel, Sharing } from '../';


const SLIDE_OPTIONS = {
  loop: false,
  customClass: 'events-slider',
  align: 'start',
  buttons: true,
  dots: false,
}
const vocabularyEventLocations = 'product.alba.vocabularies.EventLocations';


const ItemView = props => {
  const {item} = props
  const itemEventLocations = useSelector(
    (state) =>
      state.vocabularies[vocabularyEventLocations] && state.vocabularies[vocabularyEventLocations].items
        ? state.vocabularies[vocabularyEventLocations].items
        : [],
    shallowEqual,
  );

  const getEventLocation = function(eventLocation) {
    if (!itemEventLocations) {
      return eventLocation
    }
    for (let item of itemEventLocations) {
      if (item.value === eventLocation) return item.label
    }
    return eventLocation
  }

  return (
    <Card fluid  key={item['@id']} as="figure" className={"eventItem eventItem-withShare " + (item?.hasPreviewImage ? 'eventItem-hasImage' : 'eventItem-noImage')} >
      
      <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])} />

      <Image  
        className="eventItem__img"
        item={item}
        loading="lazy"
        alt=""
        sizes="24rem"
        />

      <Card.Content as="figcaption" className="eventItem__text">
        <FormattedDate date={item.start} className="eventItem__date "
          format={{
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }}
          />
          {item?.event_location ? (
            <p className="eventItem__location">{getEventLocation(item.event_location)}</p>
          ) : null}

        <UniversalLink
          className="eventItem__link stretched-link"
          href={flattenToAppURL(item['@id'])}>
          {item.title}
        </UniversalLink>

        {(!item?.hasPreviewImage && item.description) ? (
          <p className="eventItem__desc">{item.description}</p>
        ) : null }
        
      </Card.Content>

    </Card>
  )
};

const Events = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const events = useSelector((state) => state.search.subrequests.events?.items);
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const getEventsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id

    if (language === 'ca')
      path = '/' + language + '/public/agenda'
    if (language === 'es')
      path = '/' + language + '/publico/eventos'
    if (language === 'en')
      path = '/' + language + '/public/events'

    return flattenToAppURL(path)
  }
  const eventsURL = getEventsURL(content);

  useEffect(() => {
    dispatch(
      searchContent(
        eventsURL,
        {
          portal_type: ['Event'],
          review_state: 'published',
          'end.query': moment().format('YYYY/MM/DD HH:MM'),
          'end.range': 'min',
          b_size: 8,
          sort_on: 'start',
          metadata_fields: ['effective', 'event_location', 'start', 'hasPreviewImage']
        },
        'events',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyEventLocations, lang: content.language.token }));
  }, [dispatch]);

  return !isEmpty(events) ? (
      <Segment as="section" basic id="homeEvents" className="events homeSectionLayout wrapperNewsEvents__events">
        <div className="secCol1 d-lg-flex flex-lg-column align-items-start">
          <Header as="h2" size="large" className="mt-0 events__title"><FormattedMessage id="Events" defaultMessage="Events"/></Header>
          <p className="ff-headings fw-bold events__desc"><FormattedMessage id="Subtitle events" defaultMessage="Subtitle events"  /></p>
          <UniversalLink href={eventsURL} className="ui primary button events__button mt-lg-auto mb-4 d-print-none">
            <FormattedMessage id="View more" defaultMessage="View more"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
          </UniversalLink>
        </div>
        <div className="secCol2">
          <Carousel slides={events.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />
        </div>
      </Segment>
  ) : (
    <></>
  );
};

export default injectIntl(Events);