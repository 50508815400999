export const Schema = (props) => {
  return {
    title: 'Job Offers',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
    },
    required: ['title'],
  };
};
