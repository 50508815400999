import react from 'react';
import { Offers } from '../../';
import { Segment, Header } from 'semantic-ui-react';

const View = (props) => {

  const { data } = props;
  return (
    <Segment basic className="px-0">
      {data.title && <Header as="h2">{data.title}</Header>}
      <Offers></Offers>
    </Segment>
  );
};

export default View;
