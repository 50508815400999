import { Container, Segment, Header, Card, List, Image } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBullhorn, faUsers, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { keys, isEmpty, map } from 'lodash';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import { Icon} from '../../';

import imgBgBanners from './img/bg-banners.jpg';


const View = (props) => {
  const { data, mode } = props;
  const fullWidthClass = ' ' + (mode !== 'edit' ? 'full-width' : '');
  const backgroundImage = data?.backgroundImage ? (data?.backgroundImage + '/@@images/image/huge') : imgBgBanners

  return (
    
    <div id="banners"  className={'bannersHome ' + fullWidthClass} style={{'--sa-bgBanners': 'url(' + backgroundImage +')'}} >
        {!isEmpty(data?.items) && (
          <Card.Group>
            {map(data.items , (item) => (
              <Card key={item['@id']} as="article" className="bannersHome__item bannersHome__item-profiles position-relative ">
              
              {(item?.icon || item?.image) && (
                <div className="bannersHome__item__image">
                  {item?.image && (
                    <img className='ui image'
                      src={
                        getScaleUrl(getFieldURL(item.image), 'preview')
                      }
                      alt={item.title ? item.title : ''}
                      />
                  )}
                  {item?.icon && !item?.image && (
                    <Icon className="iconLinkItem__icon" name={item.icon} />
                  )}
                </div>
              )}
              <Card.Content className="bannersHome__item__text">
                <Card.Header as="h3" className="bannersHome__item__title">
                  {item.title}
                </Card.Header>
                {(item?.description || item?.links) && (
                  <Card.Description as="div" className="bannersHome__item__desc">
                    {item?.description && (<span>{item.description}</span>)}

                    {item?.links && (
                      <ul className="list-unstyled mb-0">
                        {map(item.links , (item) => (
                          <li key={item['@id']} className="mb-2">
                            {(item?.link && mode !== 'edit') ? (
                              <UniversalLink href={item.link} className="bannersHome__item-profiles__link fs-5">
                                {item.title} <FontAwesomeIcon icon={faArrowRight} />
                              </UniversalLink>
                            ) : (
                              <>
                                {item.title} <FontAwesomeIcon icon={faArrowRight} />
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </Card.Description>
                )}
                {item?.link && (
                  <>
                  { item?.textLink ? (
                    <UniversalLink href={item.link} className="bannersHome__item__more ">{item?.textLink}</UniversalLink>
                  ) : (
                    <UniversalLink href={item.link} className="bannersHome__item__more ">
                      <FormattedMessage id="View more" defaultMessage="View more"  />
                      <span className="visually-hidden">{item.title}</span>
                    </UniversalLink>
                  )}
                  </>
                )}
              </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
    </div>
  )
};

export default View;