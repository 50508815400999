/**
 * EventsListing component.
 * @module components/Events/EventsListing
 */

import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form, ButtonGroup, ButtonOr } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image, Icon } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toBackendLang } from '@plone/volto/helpers/Utils/Utils';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import leftKey from '@plone/volto/icons/left-key.svg';
import rightKey from '@plone/volto/icons/right-key.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import { faCalendarDays, faCalendar, faGrip } from '@fortawesome/free-solid-svg-icons';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "dayjs/locale/es";
import "dayjs/locale/ca";
import { getVocabulary } from '@plone/volto/actions';
import { Sharing } from '../';


const messages = defineMessages({
  labelText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: '?',
  },
  startDate: {
    id: 'Start Date',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'End Date',
    defaultMessage: 'End Date',
  },
  showCalendar: {
    id: 'Show calendar',
    defaultMessage: 'Show calendar',
  },
  showGrid: {
    id: 'Show grid',
    defaultMessage: 'Show grid',
  },
  next: {
    id: 'Next',
    defaultMessage: 'Next',
  },
  previous: {
    id: 'Previous',
    defaultMessage: 'Previous',
  },
  today: {
    id: 'Today',
    defaultMessage: 'Today',
  },
});

const vocabularyPortalTypes = 'plone.app.vocabularies.UserFriendlyTypes';

const PrevIcon = () => (
  <div
    className="prev-icon"
    style={{
      color: '#000',
      left: '22px',
      padding: '5px',
      position: 'absolute',
      top: '15px',
    }}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex="0"
  >
    <Icon name={leftKey} size="30px" />
  </div>
);
const NextIcon = () => (
  <div
    className="next-icon"
    style={{
      color: '#000',
      right: '22px',
      padding: '5px',
      position: 'absolute',
      top: '15px',
    }}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex="0"
  >
    <Icon name={rightKey} size="30px" />
  </div>
);

const CloseIcon = () => <Icon name={clearSVG} size="24px" className="close" />;

const EventsListing = (props) => {
  const { content } = props;
  const lang = content.language.token;
  const dispatch = useDispatch();
  const intl = useIntl();
  const events = useSelector((state) => state.search.subrequests.events?.items);
  const total = useSelector((state) => state.search.subrequests.events?.total);
  const customRoot = useSelector((state) => state?.navroot?.data?.custom_root);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [mode, setMode] = useState('future');
  const [search, setSearch] = useState(0);
  const [searchableTextInput, setSearchableText] = useState('');
  const [searchableText] = useDebounce(searchableTextInput, 500);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focused, setFocused] = useState(false);
  const [calendarMode, setCalendarMode] = useState(false);
  const [count, setCount] = useState(0);
  const vocabularyEventLocations = 'product.alba.vocabularies.EventLocations';
  const itemEventLocations = useSelector(
    (state) =>
      state.vocabularies[vocabularyEventLocations] && state.vocabularies[vocabularyEventLocations].items
        ? state.vocabularies[vocabularyEventLocations].items
        : [],
    shallowEqual,
  );

  const getEventLocation = function(eventLocation) {
    if (!itemEventLocations) {
      return eventLocation
    }
    for (let item of itemEventLocations) {
      if (item.value === eventLocation) return item.label
    }
    return eventLocation
  }

  const getEventsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.language.token

    if (customRoot['@type'] === 'Industry') {
      path = content['@id']
    }
    if (customRoot['@type'] === 'Instrument') {
      path = '/' + language
    }

    return flattenToAppURL(path)
  }
  const eventsURL = getEventsURL(content)

  useEffect(() => {
    setCount(count + 1);
    let contentFilter = {
        portal_type: ['Event'],
        b_size: pageSize,
        b_start: currentPage * pageSize,
        review_state: 'published',
        SearchableText: searchableText,
        sort_on: 'start',
        metadata_fields: ['start', 'end', 'hasPreviewImage', 'event_location']
    };
    switch (mode) {
      case 'future':
        contentFilter['end.query'] = [moment().format('YYYY/MM/DD HH:MM')]
        contentFilter['end.range'] = 'min'
        break;
      case 'past':
        contentFilter['end.query'] = [moment().format('YYYY/MM/DD HH:MM')]
        contentFilter['end.range'] = 'max'
        contentFilter.sort_order = 'reverse';
        break;
      case 'daterange':
        contentFilter['start.query'] = [startDate?.format('YYYY/MM/DD') + ' 00:00', endDate?.format('YYYY/MM/DD') + ' 23:59']
        contentFilter['start.range'] = 'min:max'
        break;
    }

    if (customRoot['@type'] === 'Instrument') {
      contentFilter['related_instruments.query'] = [customRoot.UID];
      contentFilter['related_instruments.operator'] = 'and';
    }

    dispatch(
      searchContent(
        eventsURL,
        contentFilter,
        'events',
      ),
    );
  }, [dispatch, currentPage, pageSize, mode, searchableText, startDate, endDate, calendarMode, search]);

  useEffect(() => {
    const searchForm = document.querySelector('.searchList');
    const searchFormPos = searchForm ? searchForm.offsetTop : 0;
    let scrollPos = window.scrollY;
    if (scrollPos > searchFormPos && searchForm) {
      window.scrollTo({top: searchFormPos})
    }

  }, [currentPage]);

  useEffect(() => {
    if (isEmpty(events) && count === 1) {
      setMode('past');
    }
  }, [events]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    setSearchableText(data.value);
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  const onCalendarMonthChange = (data) => {
    setMode('daterange');
    setCurrentPage(0);
    setStartDate(dayjs(data).startOf('month'));
    setEndDate(dayjs(data).endOf('month'));
  }

  const onClickFutureMode = (event) => {
    event.preventDefault()
    setMode('future');
    setStartDate(null)
    setEndDate(null)
    setCurrentPage(0);
  };

  const onClickPastMode = (event) => {
    event.preventDefault()
    setMode('past');
    setStartDate(null)
    setEndDate(null)
    setCurrentPage(0);
  };

  const onDatesChange = (data) => {
    if (data.startDate && data.endDate) {
      setMode('daterange');
      setCurrentPage(0);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    } else {
      setMode('future')
      setCurrentPage(0);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
    }
  };

  const onCalendarModeChange = (data) => {
    if (data) {
      dayjs.locale(lang);
      setCalendarMode(true)
      setMode('daterange');
      setCurrentPage(0);
      setStartDate(dayjs().startOf('month'));
      setEndDate(dayjs().endOf('month'));
      setPageSize(100);
    } else {
      setCalendarMode(false)
      setMode('future')
      setStartDate(null)
      setEndDate(null)
      setCurrentPage(0);
      setPageSize(12);
    }
  };

  const components = { 
    event: props => {
      return <>
          <UniversalLink
            className="" 
            href={flattenToAppURL(props.event['@id'])}>
            {props.title}
          </UniversalLink>
        </>
    }
  }

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyPortalTypes, lang: content.language.token }));
  }, [dispatch]);


  const localizer = dayjsLocalizer(dayjs);
  return (
    <>
      <BodyClass className='eventslisting headerNegativeBottom'/>
      {hasBlocksData(content) ? (
        <Container className="userBlocks">
            <RenderBlocks {...props} />
        </Container>
      ): null}

      <Container fluid className=" d-print-none">
        <div className={'searchList ' + ( calendarMode ? ' searchList-showCalendar' : ' searchList-showGrid')} >

          <ButtonGroup className="calendarSwitch">
            <Button className={'buttonSwitchCalendar' + ( calendarMode ? ' active' : ' ')} onClick={() => onCalendarModeChange(true)} icon   >
              <FontAwesomeIcon aria-hidden="true" title={intl.formatMessage(messages.showCalendar)} icon={faCalendarDays} />
              <span className="visually-hidden"><FormattedMessage id="Show calendar" defaultMessage="Show calendar"  /></span>
            </Button>
            <Button className={'buttonSwitchDate' + (!calendarMode ? ' active' : ' ')} onClick={() => onCalendarModeChange(false)} icon  >
              <FontAwesomeIcon icon={faGrip} aria-hidden="true" title={intl.formatMessage(messages.showGrid)} />
              <span className="visually-hidden"><FormattedMessage id="Show grid" defaultMessage="Show grid"  /></span>
            </Button>
          </ButtonGroup>

          {!calendarMode ? (
            
              <Form  className="searchList__form searchList-events" onSubmit={onSubmit}>
                <Form.Group >
                  <div className="searchList__timeButtons">
                    <Form.Button inverted {...(mode === 'future' ? {primary: true} : {secondary: true})} onClick={onClickFutureMode} className="">
                      <FormattedMessage id="Future events" defaultMessage="Future events"/>
                    </Form.Button>
                    <Form.Button inverted {...(mode === 'past' ? {primary: true} : {secondary: true})} onClick={onClickPastMode} className="">
                      <FormattedMessage id="Past events" defaultMessage="Past events"/>
                    </Form.Button>
                  </div>
                  <Form.Input 
                    id="textInputId"
                    fluid 
                    label={intl.formatMessage(messages.labelText)} 
                    focus
                    name="searchableText"
                    value={searchableTextInput}
                    onChange={onChangeSearchableText}
                    placeholder={intl.formatMessage(messages.placeholderText)} 
                    type="text" 
                    className="searchList__textInput"
                    />

                  <div className="ui form date-time-widget-wrapper searchList__dateSelect">
                    <span className="searchList__label"><FormattedMessage id="Date range" defaultMessage="Date range"  /></span>
                    <div className="ui input date-input">
                      <DateRangePicker
                        startDate={startDate}
                        startDateId="startDate"
                        startDatePlaceholderText={intl.formatMessage(messages.startDate)}
                        endDate={endDate}
                        endDateId="endDate"
                        onDatesChange={onDatesChange}
                        endDatePlaceholderText={intl.formatMessage(messages.endDate)}
                        numberOfMonths={1}
                        noBorder
                        showClearDates
                        displayFormat={moment
                          .localeData(toBackendLang(lang))
                          .longDateFormat('L')}
                        focusedInput={focused}
                        onFocusChange={(focusedInput) => setFocused(focusedInput)} 
                        isOutsideRange={() => false}
                        />
                    </div>
                  </div>            

                </Form.Group>
                <Form.Button className="searchList__send searchList__btn" primary><FormattedMessage id="Search" defaultMessage="Search"  /></Form.Button>
              </Form>

          ) : (
            <Segment className="calendarWrapper" basic >
              <Calendar
                localizer={localizer}
                events={events}
                views={['month']}
                defaultView="month"
                min={dayjs().startOf('month').toDate()}
                max={dayjs().endOf('month').toDate()}
                formats={{
                  dayHeaderFormat: date => {
                    return dayjs(date).toDate()
                  }
                }}
                messages={{next: intl.formatMessage(messages.next), previous: intl.formatMessage(messages.previous), today: intl.formatMessage(messages.today)}} 
                components={components}
                onNavigate={onCalendarMonthChange}
              />
            </Segment>
          )}
            
        </div>
      </Container>
      
      <Segment basic>
        {!isEmpty(events) ? (
          <Container className="events"> 
            <Grid>
              {events.map((item) => (
                <Grid.Column mobile={12} tablet={6} computer={4} widescreen={3} key={item['@id']}>

                  <Card fluid as="figure" className={'newsItem newsItem--event ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')}>                      
                    <div className="ui image newsItem__img__wrapper">
                      <Image 
                        className="newsItem__img"
                        item={item}
                        loading='lazy'
                        alt=""
                        sizes="(max-width:767px) 100vw,
                                (max-width:991px) 50vw,
                                (max-width:1199px) 33vw,
                                25vw"
                      />
                      <div className="newsItem__type newsItem__type-event">
                        {item?.Subject ? (
                            <Label.Group className="subjects ui label">
                              {map(item.Subject, (item, index) => (
                                <Label key={index}>{item}</Label>
                              ))}
                            </Label.Group>
                        ) : null}
                        <Label as="time" className="ui label" dateTime={item.start}>
                          <FormattedDate date={item.start}  
                            format={{
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }}
                          />
                          &nbsp;-&nbsp;
                          <FormattedDate date={item.start} 
                            format={{
                              hour: "numeric",
                              minute: "numeric",
                            }}
                          />
                        </Label>
                      </div>
                    </div>
                      <Card.Content as="figcaption" className="newsItem__text pt-4">         
                        {item?.event_location && (
                        <Card.Meta className="eventItem__location eventItem__location--list">
                          {getEventLocation(item.event_location)}
                        </Card.Meta>)}

                        <Card.Header className="d-flex justify-content-between flex-row headerHasSocialShare align-items-start flex-nowrap">
                          <UniversalLink
                            className="newsItem__link newsItem__title stretched-link me-3" 
                            href={flattenToAppURL(item['@id'])}>
                            {item.title}
                          </UniversalLink>
                          {item?.hasPreviewImage && 
                            <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])}  /> 
                          }
                        </Card.Header>

                        {(!item?.hasPreviewImage && item?.description) &&
                          <p className="eventItem__desc">{item.description}</p>
                        }

                        {!item?.hasPreviewImage && 
                          <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])} /> 
                        }
                        
                      </Card.Content>
                  </Card>

                </Grid.Column>
              ))}
            </Grid>

            {total > pageSize && (
              <nav className="pagination">
                <Pagination 
                  current={currentPage}
                  total={Math.ceil(total / pageSize)}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                />
              </nav>
            )}

          </Container>
        ) : (
          <Container className="events"> 
            <FormattedMessage id="No events found" defaultMessage="No events found"  />
          </Container>
          )} 
      </Segment>


    </>
  );
};


export default EventsListing;