export async function getCharts(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors', // Change to 'cors' instead of 'no-cors'
      headers: {
        'Accept': 'application/json',
        // Remove 'Content-Type' header to avoid CORS preflight issues
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error; // Re-throw the error after logging it
  }
}