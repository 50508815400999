/**
 * NewsListing component.
 * @module components/News/NewsListing
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { Sharing } from '../';


const messages = defineMessages({
  labelText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: 'What are you looking for?',
  },
  defaultOptionInstruments: {
    id: 'All instruments',
    defaultMessage: 'All instruments',
  },
  defaultOptionResearchAreas: {
    id: 'All areas',
    defaultMessage: 'All areas',
  },
  defaultOptionTypes: {
    id: 'All types',
    defaultMessage: 'All types',
  },
  labelInstrument: {
    id: 'Instrument',
    defaultMessage: 'Instrument',
  },
  labelResearchArea: {
    id: 'Research area',
    defaultMessage: 'Research area',
  },
  labelType: {
    id: 'Type',
    defaultMessage: 'Type',
  },
  placeholderAllInstruments: {
    id: 'All instruments',
    defaultMessage: 'All instruments',
  },
  placeholderAllResearchAreas: {
    id: 'All areas',
    defaultMessage: 'All areas',
  },
  placeholderAllTypes: {
    id: 'All types',
    defaultMessage: 'All types',
  }
});


const NewsListing = (props) => {
  const { content } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const total = useSelector((state) => state.search.subrequests.news?.total);
  const customRoot = useSelector((state) => state?.navroot?.data?.custom_root);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [search, setSearch] = useState(0);
  const [instrument, setInstrument] = useState('');
  const [researchArea, setResearchArea] = useState('');
  const [typeN, setTypeN] = useState('');
  const [searchableTextInput, setSearchableText] = useState('');
  const [searchableText] = useDebounce(searchableTextInput, 500);

  const vocabularyInstruments = 'product.alba.vocabularies.Instruments';
  const vocabularyResearchAreas = 'product.alba.vocabularies.ResearchAreas';
  const vocabularyNewsitemTypes = 'product.alba.vocabularies.NewsitemTypes';

  const itemsInstruments = useSelector(
    (state) =>
      state.vocabularies[vocabularyInstruments] && state.vocabularies[vocabularyInstruments].items
        ? [{value: '', label: intl.formatMessage(messages.defaultOptionInstruments)}, ...state.vocabularies[vocabularyInstruments].items]
        : [],
    shallowEqual,
  );
  const itemsResearchAreas = useSelector(
    (state) =>
      state.vocabularies[vocabularyResearchAreas] && state.vocabularies[vocabularyResearchAreas].items
        ? [{value: '', label: intl.formatMessage(messages.defaultOptionResearchAreas)}, ...state.vocabularies[vocabularyResearchAreas].items]
        : [],
    shallowEqual,
  );
  const itemsNewsitemTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyNewsitemTypes] && state.vocabularies[vocabularyNewsitemTypes].items
        ? [{value: '', label: intl.formatMessage(messages.defaultOptionTypes)}, ...state.vocabularies[vocabularyNewsitemTypes].items]
        : [],
    shallowEqual,
  );

  const checkOptions = ['highlights', 'resultados-cientificos', 'resultats-cientifics']

  const getNewsURL = function (content) {
    let language = content.language.token
    let path = content['@id']

    if (customRoot['@type'] === 'Industry' || customRoot['@type'] === 'Instrument' || checkOptions.some(option => content['@id'].includes(option))) {
      path = '/' + language
    }
    return flattenToAppURL(path)
  }
  const newsURL = getNewsURL(content)

  const getNewsItemType = function(typeN) {
    if (!itemsNewsitemTypes) {
      return typeN
    }
    for (let item of itemsNewsitemTypes) {
      if (item.value === typeN) return item.label
    }
    return typeN
  }

  useEffect(() => {
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyInstruments, lang: content.language.token }));
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyResearchAreas, lang: content.language.token }));
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyNewsitemTypes, lang: content.language.token }));
  }, [dispatch]);

  useEffect(() => {
    let contentFilter = {
      portal_type: ['News Item'],
      b_size: pageSize,
      b_start: currentPage * pageSize,
      review_state: 'published',
      SearchableText: searchableText,
      related_instruments: instrument,
      newsitem_types: typeN,
      research_areas: researchArea,
      sort_on: 'effective',
      sort_order: 'reverse',
      metadata_fields: ['effective', 'newsitem_types',  'hasPreviewImage']
    }
    if (customRoot['@type'] === 'Industry') {
      delete contentFilter.newsitem_types
      contentFilter['newsitem_types.query'] = typeN ? ['2', typeN] : ['2'];
      contentFilter['newsitem_types.operator'] = 'and';
    }
    if (checkOptions.some(option => content['@id'].includes(option))) {
      delete contentFilter.newsitem_types
      contentFilter['newsitem_types.query'] = typeN ? ['1', typeN] : ['1'];
      contentFilter['newsitem_types.operator'] = 'and';
    }
    if (customRoot['@type'] === 'Instrument') {
      delete contentFilter.related_instruments
      contentFilter['related_instruments.query'] = instrument ? [customRoot.UID, instrument] : [customRoot.UID];
      contentFilter['related_instruments.operator'] = 'and';
    }
    dispatch(
      searchContent(
        newsURL,
        contentFilter,
        'news',
      ),
    );
  }, [dispatch, currentPage, pageSize, searchableText, instrument, researchArea, typeN, search]);

  useEffect(() => {
    const searchForm = document.querySelector('.searchList');
    const searchFormPos = searchForm ? searchForm.offsetTop : 0;
    let scrollPos = window.scrollY;
    if (scrollPos > searchFormPos && searchForm) {
      window.scrollTo({top: searchFormPos})
    }

  }, [currentPage]);

  const onChangePage = (event, activePage) => {
    event.preventDefault();
    setCurrentPage(activePage.value);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    setSearchableText(data.value);
    setCurrentPage(0);
  };

  const onChangeInstrument = (event, data) => {
    event.preventDefault();
    setInstrument(data.value);
    setCurrentPage(0);
  };

  const onChangeResearchArea = (event, data) => {
    event.preventDefault();
    setResearchArea(data.value);
    setCurrentPage(0);
  };

  const onChangeTypeN = (event, data) => {
    event.preventDefault();
    setTypeN(data.value);
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  return (
    <>
      <BodyClass className='newslisting headerNegativeBottom'/>
      
      {hasBlocksData(content) ? (
        <Container  className="userBlocks">
          <RenderBlocks {...props} />
        </Container>
      ): null}

      <Container fluid className="pb-4  d-print-none">
        <Form  className="searchList   " onSubmit={onSubmit}>          
          <Form.Group widths="equal">
            <Form.Input 
              id="textInputId"
              fluid 
              label={intl.formatMessage(messages.labelText)} 
              focus
              name="searchableText"
              value={searchableTextInput}
              onChange={onChangeSearchableText}
              placeholder={intl.formatMessage(messages.placeholderText)} 
              type="text" 
              />
            {customRoot['@type'] !== 'Instrument' && (
            <Form.Select 
              id="infrastructureSelectId"
              fluid 
              label={intl.formatMessage(messages.labelInstrument)}
              options={map(itemsInstruments, (item, index) => {return {
                key: index,
                value: item.value,
                text: item.label,
              }})}
              name="instrument"
              value={instrument}
              onChange={onChangeInstrument}
              placeholder={intl.formatMessage(messages.placeholderAllInstruments)}
              />
            )}
            <Form.Select 
              id="researchSelectId"
              fluid 
              label={intl.formatMessage(messages.labelResearchArea)}
              options={map(itemsResearchAreas, (item, index) => {return {
                key: index,
                value: item.value,
                text: item.label,
              }})}
              name="researchArea"
              value={researchArea}
              onChange={onChangeResearchArea}
              placeholder={intl.formatMessage(messages.placeholderAllResearchAreas)}
              />
            <Form.Select 
              id="typeSelectId"
              fluid 
              label={intl.formatMessage(messages.labelType)}
              options={map(itemsNewsitemTypes, (item, index) => {return {
                key: index,
                value: item.value,
                text: item.label,
              }})}
              name="typeN"
              value={typeN}
              onChange={onChangeTypeN}
              placeholder={intl.formatMessage(messages.placeholderAllTypes)} 
              />
          </Form.Group>
          <Form.Button className="searchList__send searchList__btn" primary><FormattedMessage id="Search" defaultMessage="Search"  /></Form.Button>
        </Form>
      </Container>


      <Segment basic>
        {!isEmpty(news) ? (
          <Container className="news"> 
            <Grid>
              {news.map((item) => (
                <Grid.Column mobile={12} tablet={6} computer={4}  widescreen={3} key={item['@id']}>
                  <Card fluid as="figure" className={'newsItem newsItem-withShare ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')}> 
                    
                    <Sharing id={item['@id']} title={item.title} url={flattenToAppURL(item['@id'])} />
                      
                      <div className="ui image newsItem__img__wrapper">
                        <Image 
                          className="newsItem__img"
                          item={item}
                          loading='lazy'
                          alt=""
                          sizes="(max-width:767px) 100vw,
                                (max-width:991px) 50vw,
                                (max-width:1199px) 33vw,
                                25vw"
                        />
                        {item?.newsitem_types  ? (
                          <Label.Group className="subjects newsItem__type">
                            {map(item.newsitem_types , (item, index) => (
                              <Label key={index}>{getNewsItemType(item)}</Label>
                            ))}
                          </Label.Group>
                        ) : null}
                      </div>

                      <Card.Content as="figcaption" className="newsItem__text">                            

                        <Card.Meta>
                          <FormattedDate date={item.effective} className="newsItem__date "
                            format={{
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }}
                          />
                        </Card.Meta>

                        <Card.Header>
                          <UniversalLink
                            className="newsItem__link newsItem__title stretched-link" 
                            href={flattenToAppURL(item['@id'])}>
                            {item.title}
                          </UniversalLink>
                        </Card.Header>

                      </Card.Content>

                  </Card>
                </Grid.Column>
              ))}
            </Grid>

            {total > pageSize && (
              <nav className="pagination">
                <Pagination 
                  current={currentPage}
                  total={Math.ceil(total / pageSize)}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                />
              </nav>
            )}

          </Container>
        ) : (
          <Container className="news"> 
            <FormattedMessage id="No news found" defaultMessage="No news found"  />
          </Container>
          )} 
      </Segment>


    </>
  );
};


export default NewsListing;
