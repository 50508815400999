import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Search } from 'semantic-ui-react';
import _ from 'lodash';
import { Map } from '../../';
import { Input } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { faTradeFederation } from '@fortawesome/free-brands-svg-icons';


const LeafletWidget = (props) => {
  const intl = useIntl();
  const {
    id,
    value,
    onChange,
    onBlur,
  } = props;
  const inputId = `field-${id}`;
  const getInitialPosition = function () {
    if (! value ) {
      return config.settings.mapCenter
    }
    return value.split(';').map(parseFloat);
  };

  const [position, setPosition] = useState(getInitialPosition);

  const onMarkerMove = (value) => {
    const newValue = (value?.lat && value?.lng) ? value?.lat + ';' + value?.lng: undefined
    onChange(id, newValue === '' ? undefined : newValue);
  };

  const handleInputChange = ({ target }) => {
    const coords = target.value.split(';').map(parseFloat);
    if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
      setPosition(coords);
      onChange(id, target.value);
    }
  };

  return (
    <>
      <FormFieldWrapper {...props} className="leaflet-widget">
        <Input
          id={inputId}
          name={id}
          type="text"
          onChange={handleInputChange}
          onBlur={({ target }) =>
            onBlur(id, target.value === '' ? undefined : target.value)
          }
          value={value || ''}
        />
      </FormFieldWrapper>
      <Map marker={position} center={position} draggable={true} onChange={onMarkerMove} />
    </>
  );
};

export default LeafletWidget;
