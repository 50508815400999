import { useEffect } from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';


const DefaultContactFormComponent = (props) => {
  const { content } = props;
  const navroot = useSelector((state) => state.navroot?.data);
  const history = useHistory();
  const language = useSelector((state) => state.intl.locale);

  useEffect(() => {
    if (language === 'ca') {
      history.replace(flattenToAppURL('/ca/que-es-alba/contacte'));
    }
    if (language === 'es') {
      history.replace(flattenToAppURL('/es/acerca-de/contacto'));
    }
    if (language === 'en') {
      history.replace(flattenToAppURL('/en/about/contact'));
    }
  }, [content, history]);
  
  return (<></>)

};

export default DefaultContactFormComponent;
