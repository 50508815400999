import React from 'react'; 
import Charts from './Charts.jsx';
import { Container, Segment } from 'semantic-ui-react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { hasBlocksData } from '@plone/volto/helpers';
import { Attachments} from '../';

import { FormattedMessage } from 'react-intl';

const Infographic = (props) => {
  
  const { content } = props;

  return (
    <> 
    <Container className="mainInfographic">
      {hasBlocksData(content) ? (           
        <Segment basic as="section" className="mainInfoData">
          <h2 className="visually-hidden"><FormattedMessage id="Featured data" defaultMessage="Featured data" /></h2>
          <RenderBlocks {...props} />
        </Segment>              
      ): null}
      <Segment as="section" basic className="wrapperInfographic">
        <h2 className="visually-hidden"><FormattedMessage id="Charts" defaultMessage="Charts" /></h2>
        <Charts {...props} />
      </Segment>
    </Container>
    <Attachments {...content} />
    </>
  );
}

export default Infographic;
