export const getFilteredPublications = (filterPublications) => {
  return fetch('https://public.cells.es/duo-services/publications/', {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  })
  .then((data) => data.json())
  .then((publications) => {
    if (filterPublications) {
      return publications.filter((publication) => publication.BLlist.includes(filterPublications))
    }
    return publications
  })
  .then((data) => {
    return data.sort((a, b) => b.idpub - a.idpub);
  });
};
