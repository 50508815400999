/**
 * Blog View component.
 * @module components/View/BlogView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Container as SemanticContainer, Card } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { BodyClass } from '@plone/volto/helpers';
import { keys, isEmpty, map, filter } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Announcements } from '../';


const BlogView = ({ content }) => {
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const PreviewImage = config.getComponent({ name: 'PreviewImage' }).component;
  const items = filter(content.items, (item) => {
    return item['@type'] !== 'Image' && !item.exclude_from_nav
  })

  const getViewURL = function (item) {
    let URL = flattenToAppURL(item['@id'])
    if (item['@type'] === 'File') {
      URL += '/@@display-file/file'
    }
    if (item['@type'] === 'Image') {
      URL += '/@@display-file/image'
    }
    if (item?.getRemoteUrl) {
      URL = item?.getRemoteUrl
    }
    return URL
  }

  return (
    <>
      <BodyClass className='blogView' />

      {content.enable_announcements && (
        <Announcements content={content} />
      )}

      <Container id="page-home">
        <section id="content-core" className="blogView__page pt-5">
          {items.map((item) => (
            <Card
              as="article"
              key={item.url}
              fluid
              className={'blogItem ' + (item.image_field ? 'blogItem-hasImage' : 'blogItem-noImage')}
            >
              <figure className="blogItem__imgWrapper">
                {item.image_field && (
                  <div className="blogItem__img__wrapper">
                    <PreviewImage
                      item={item}
                      alt={item.image_caption ? item.image_caption : ''}
                      className="blogItem__img"
                      responsive={true}
                      sizes="(min-width: 1600px) 500px, (min-width: 1280px) 400px, (min-width: 600px) 200px, 50vw"
                      loading="lazy"
                    />
                  </div>
                )}
                <span className="squareMore" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight} /></span>
              </figure>
              <Card.Content className="blogItem__text">
                <Card.Header as="h2" className="blogItem__title">
                  <UniversalLink href={getViewURL(item)} className="blogItem__link stretched-link">
                    <span className="blogItem__link__text">{item.title}</span> 
                  </UniversalLink>
                </Card.Header>
                {item.description && (
                  <Card.Description as="p" className="blogItem__desc">{item.description}</Card.Description>
                )}
              </Card.Content>
            </Card>
          ))}
        </section>
      </Container>
    </>
  );
};

export default BlogView;
