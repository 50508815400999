/**
 * Slides component.
 * @module components/Instrument/Slides
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Button } from 'semantic-ui-react';
import { keys, isEmpty } from 'lodash';

import { UniversalLink, Image } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL, BodyClass } from '@plone/volto/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import { FormattedMessage } from 'react-intl';

import { Carousel } from '../';


const SLIDE_OPTIONS = {
  loop: true,
  autoplay: true,
  dots: true,
  buttons: true,
  customClass: 'main-slider',
  stopOnMouseEnter: true
}


const ItemView = props => {
  const {item} = props

  return (
      <article className=" slideItem slideItem--instrument " key={item['@id']}>
          <Image
            item={item}
            alt=""
            className="slideItem__image"
            sizes="(max-width: 75em) 100vw, (max-width: 100em) 1119px, 1520px"
          />
          <div className="slideItem__text slideItem__text--instrument">
            <h3 className="slideItem__title">
              <UniversalLink
                className="itemm slideItem__link stretched-link"
                href={item.remoteUrl}>          
                {item.title}
              </UniversalLink>
            </h3>
            {item?.description ? (
              <p className="slideItem__desc mb-0">
                {item.description}
              </p>
            ) : null }
            <p>
              <Button as="span" inverted className="slideItem__readmore btn btn-white">
                {item.button_text ? item.button_text : <FormattedMessage id="readMore" defaultMessage="Read more" /> } <FontAwesomeIcon className="ms-3" icon={faArrowRightLong}  />
              </Button>
            </p>
          </div>
      </article>
  )

};

const Slides = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const slides = useSelector((state) => state.search.subrequests.slides?.items);

  const getSlidesPath = function (content) {
    let language = content.language.token
    let itemPath = flattenToAppURL(content['@id'])
    let path = itemPath + '/home/slides'

    if (language === 'ca')
      path = itemPath + '/portada/slides'
    if (language === 'es')
      path = itemPath + '/portada/slides'

    return path
  }


  React.useEffect(() => {
    dispatch(
      searchContent(
        getSlidesPath(content),
        {
          portal_type: ['Slide'],
          review_state: 'published',
          b_size: 8,
          metadata_fields: ['remoteUrl', 'button_text']
        },
        'slides',
      ),
    );
  }, [dispatch]);

  return !isEmpty(slides) ? (
    <>
      <BodyClass className="pageHasSlides" /> 
      <Segment id="featuredSection" as="section" basic className="slides slidesWrapper">
        <h2 className="visually-hidden"><FormattedMessage id="featured" defaultMessage="Featured" /></h2>
        <Carousel slides={slides.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />
      </Segment>
    </>
  ) : (
    <>
      <BodyClass className="pageHasNoSlides" /> 
    </>
  );
};

export default Slides;
