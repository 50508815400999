/**
 * Menu component.
 * @module components/Industry/Menu
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { keys, isEmpty, map } from 'lodash';
import { UniversalLink, Image } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL, BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Icon} from '../';


const Menu = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.search.subrequests.menu?.items);

  useEffect(() => {
    dispatch(
      searchContent(
        flattenToAppURL(content['@id']),
        {
          portal_type: ['Folder', 'Document', 'Link'],
          review_state: 'published',
          metadata_fields: ['content_icon', 'remoteUrl'],
          exclude_from_nav: 'false',
          'path.query': flattenToAppURL(content['@id']),
          'path.depth': 1,
          sort_on: 'getObjPositionInParent',
        },
        'menu',
      ),
    );
  }, [dispatch]);

  return !isEmpty(menu) && (
    <>
      <nav className="instrumentMenu">
      <Grid columns={12}>
        <Grid.Row>
          {map(menu, (item, index) => (
            <Grid.Column key={index} mobile={12} tablet={6} computer={4} widescreen={3}>
              <UniversalLink className="instrumentMenu__item " href={flattenToAppURL(item['@id'])}>
                {item.content_icon && (<Icon className="instrumentMenu__item__icon" name={item.content_icon} />)}
                <span className="instrumentMenu__item__text ">{item.title}</span>
              </UniversalLink> 
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      </nav>
    </>
  );
};

export default Menu;
