/**
 * Landing component.
 * @module components/Landing/Landing
 */

import React from 'react';
import { Container } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import Offers from './Offers';

const Landing = (props) => {
  const { content } = props;
  return (
    <>
      <BodyClass className='landing'/>

      {hasBlocksData(content) ? (
        <Container className="userBlocks">
          <RenderBlocks {...props} />
        </Container>
      ): null}
      {content['@id'].includes('careers') && (
        <Offers limit={4}></Offers>
      )}

    </>
  );
};


export default Landing;
