import React from 'react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink } from '@plone/volto/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Container, Grid, Segment, Card, Header, Loader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { getFilteredPublications } from 'addons/volto-base-alba/src/services';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { keys, isEmpty, map } from 'lodash';


const View = (props) => {
  const { data, mode } = props;
  const content = useSelector((state) => state?.content?.data);
  const fullWidthClass = ' ' +  (mode !== 'edit' ? 'full-width' : '')
  const [publications, setPublications] = useState([]);
  const [loader, setLoader] = useState(true);

  const getPublicationsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id

    if (language === 'ca')
      path = '/' + language + '/ca/ciencia/publicacions'
    if (language === 'es')
      path = '/' + language + '/es/ciencia/publicaciones'
    if (language === 'en')
      path = '/' + language + '/science/publications'

    if (data?.filter) {
      path += '?instrument=' + data.filter
    }

    return flattenToAppURL(path)
  }
  const publicationsURL = getPublicationsURL(content)

  useEffect(() => {
    let mounted = true;
    getFilteredPublications(data.filter).then((items) => {
      if (mounted) {
        setPublications(items);
        setLoader(false)
      }
    });
    return () => (mounted = false);
  }, [data.filter]);

  return (
    <>
      <section className={'instrumentPublications bg-light py-5' + fullWidthClass}>
        <Container>
          <Header as="h2" size="huge" className="text-primary"><FormattedMessage id="Latest publications" defaultMessage="Latest publications"  /></Header>
          
          {!isEmpty(publications) ? (
            <>
              {publications.slice(0, 3).map((item, id) => (
                <Card as="article" fluid key={id} className="publicationItem mb-5">
                  <Card.Header as="h2" className="publicationItem__title">
                    <UniversalLink
                      className="publicationItem__link stretched-link"
                      href={flattenToAppURL(item.externalUrl)}
                    >
                      {item.Title}
                    </UniversalLink>
                  </Card.Header>
                  <Card.Content>
                    {(item.authorsList && item.authorsList !== 'None') && (
                      <p className="publicationItem__desc">{item.authorsList}</p>
                    )}
                    {(item.BLlist && item.BLlist !== 'None' && item.BLNamelist) && (
                      <>
                      {item.BLlist.split(',').map((blCode, id) => (
                        <p className="publicationItem__type">
                          <span className="">{blCode} - {item.BLNamelist.split(',')[id]}</span>
                        </p>
                      ))}
                      </>
                    )}
                    {(item.reference || item.doi) && (
                      <p className="publicationItem__subText">
                        <span>{item.reference}</span> <span>{item.doi}</span>
                      </p>
                    )}
                    <span className="squareMore" aria-hidden="true">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </Card.Content>
                </Card>
              ))}
            </>
          ) : (
            <>
              {loader ? (
                <Loader active inline='centered'>
                  <FormattedMessage id="Loading" defaultMessage="Loading" />
                </Loader>
              ) : (
                <FormattedMessage id="No publications found" defaultMessage="No publications found" />
              )}
            </>
          )}

          <p className="text-center">
            <UniversalLink href={publicationsURL} className="ui primary button mt-lg-auto mb-4 d-print-none">
              <FormattedMessage id="View more" defaultMessage="View more"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
            </UniversalLink>
          </p>
        </Container>
      </section>
    </>
  );
};

export default View;
