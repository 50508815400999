/**
 * PrintButton component.
 * @module components/Print/PrintButton
 */

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'semantic-ui-react';
import { FormattedMessage  } from 'react-intl';


const PrintButton = (props) => {
  const { content } = props;
  const print = () => window.print();

  return (
    <>
      <Button inverted className="mt-5 primary d-print-none" onClick={print}>
        <FontAwesomeIcon icon={faPrint} className="me-2 fs-6" />
        <FormattedMessage id="Print" defaultMessage="Print"  />
        <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
      </Button>
    </>
  );
};


export default PrintButton;
