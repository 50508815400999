/**
 * Industry component.
 * @module components/Industry/Industry
 */

import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, Segment, Card, Header, Button } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { Menu, Events, News } from './';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';


const Industry = (props) => {
  const { content } = props;

  return (
    <>
      <BodyClass className="headerNegativeBottom" /> 
 
      <Container>
        <Menu {...props} />
      </Container>
        
        {hasBlocksData(content) ? (
          <Container className="userBlocks">
            <RenderBlocks {...props} />
          </Container>
        ): null}

      <Container>
        <News {...props} />
      </Container>

      <Events {...props} />

    </>
  );
};


export default Industry;
