import React, { useState } from "react";
import Modal from "./Modal";
import "./_Lightbox.scss";
import { useSelector } from 'react-redux';
import { Button, Card } from 'semantic-ui-react';
import { Image } from '@plone/volto/components';
import { filter } from 'lodash';


const Lightbox = images => {  
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item); 
  };

  const handelRotationRight = () => {
    const totalLength = images.images.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = images.images[0].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;  
    const newUrl = images.images.filter((item) => {
      return images.images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = images.images.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = images.images[totalLength - 1].link;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = images.images.filter((item) => {
      return images.images.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0];
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
  };

  return (
    <div className="lightBoxWrapper wrapper">
      {images.images.map((item, index) => (
        <Card as="figure" key={index} className="wrapper-images lightBoxItem" fluid>
          <Image 
            className="lightBoxItem__img"
            item={item}
            loading='lazy'
            alt=""  
            />
          <Card.Content as="figcaption" className="lightBoxItem__caption" >
            <Button onClick={() => handleClick(item, index)}className="lightBoxItem__link" >{item.title}</Button>            
          </Card.Content>
        </Card>
      ))}
      <div>
        {clickedImg && (
          <Modal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </div>
    </div>
  );
}

export default Lightbox;