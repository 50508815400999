/**
 * Header Info actions.
 * @module actions/header_info/header_info
 */

import { GET_HEADER_INFO } from '../../constants/ActionTypes';

/**
 * Get Header Info.
 * @function getHeaderInfo
 * @param {string} url Content url.
 * @returns {Object} Get header info action.
 */
export function getHeaderInfo(url) {
  return {
    type: GET_HEADER_INFO,
    request: {
      op: 'get',
      path: `${url}/@header_info`,
    },
  };
}
