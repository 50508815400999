import header from './header/header';
import header_info from './header_info/header_info';
import navigation from './navigation/navigation';
import registry from './registry/registry';

const reducers = {
    header,
    header_info,
    navigation,
    registry,
};

export default reducers;