/**
 * Search component.
 * @module components/Frontpage/Search
 */

import React from 'react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Grid, Form, Segment, Input, Header } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { defineMessages, useIntl, FormattedMessage, injectIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getNavroot } from '@plone/volto/actions';
import { getRegistry } from '../../actions';
import { hasApiExpander, getBaseUrl } from '@plone/volto/helpers';
import { updateControlpanel, getControlpanel } from '@plone/volto/actions';
import { UniversalLink } from '@plone/volto/components';
import { keys, isEmpty, map } from 'lodash';


const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const Search = props => {
  const { content } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const history = useHistory();
  const pathname = props.pathname;
  const language = content.language.token
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const searchLinksKey = 'alba.search_links_' + language
  const searchLinks = useSelector((state) => state?.registry?.records[searchLinksKey]);
  const analyticsResults = false;

  const onChangeText = (event, { value }) => {
    setText(value);
  };

  const onSubmit = (event) => {
    const path =
      pathname?.length > 0 ? `&path=${encodeURIComponent(pathname)}` : '';

    history.push(`${navRootPath}/search?SearchableText=${encodeURIComponent(text)}${path}`);
    // reset input value
    setText('');
    event.preventDefault(); 
  };

  useEffect(() => {
    if (!hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }
    dispatch(getRegistry(searchLinksKey));
  }, [dispatch, pathname]);

  return(
      <Segment as="section" basic id="homeSearch" className="searchHome wrapperNewsEvents__search d-print-none">
  
          <Grid container>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={12} computer={7} largeScreen={7} widescreen={6}>
                
                <Header as="h2" size="huge" className="searchHome__title"><FormattedMessage id="Search" defaultMessage="Search"/></Header>
                <Form action={`${navroot?.navroot?.['@id']}/search`} onSubmit={onSubmit} className="searchHome__form">

                  <Form.Field className="searchHome__field">
                    <label htmlFor="SearchableTextHome" className="visually-hidden">{intl.formatMessage(messages.searchSite)}</label>
                    <Input
                      fluid
                      focus
                      onChange={onChangeText}
                      name="SearchableText"
                      value={text}                      
                      autoComplete="off"
                      placeholder={intl.formatMessage(messages.searchSite)} 
                      id="SearchableTextHome"
                      className="searchbox__input searchHome__input" 
                      type="search"
                      />
                  </Form.Field>
                  <Button primary type="submit" className="searchbox__button searchHome__button" >                       
                    <span className="searchbox__button__text">{intl.formatMessage(messages.search)}</span>
                    <FontAwesomeIcon className="searchbox__button__icon-arrow ms-4 ms-lg-5" icon={faArrowRightLong}  />
                  </Button>

                </Form>

                <Grid>
                  <Grid.Row className="pt-5">
                    {!isEmpty(searchLinks?.items)  && (
                    <Grid.Column mobile={12} tablet={6} computer={6} largeScreen={6} widescreen={6}>

                      <Header as="h3" className="text-uppercase mb-3 text-primary fs-5">
                        <FormattedMessage id="Explore text resources" defaultMessage="Explore text resources"/>
                      </Header>
                      <ul className="ps-3">
                        {searchLinks.items.map((item) => (
                          <li className="mb-2" key={item['@id']}>
                            <UniversalLink
                              className="link-primary text-decoration-none" 
                              href={flattenToAppURL(item.link)}>
                              {item.title}
                            </UniversalLink>
                          </li>
                        ))}
                      </ul>

                    </Grid.Column>
                    )}
                    {analyticsResults && (
                      <Grid.Column mobile={12} tablet={6} computer={6} largeScreen={6} widescreen={6} className="mt-4 mt-md-0 mt-lg-4 mt-xl-0">

                      <Header as="h3" className="text-uppercase mb-3 text-primary fs-5">People also searched for</Header>
                      <ul className="ps-3">
                          <li className="mb-2"><a href="#" className="link-primary text-decoration-none">Lorem ipsum</a></li>
                          <li className="mb-2"><a href="#" className="link-primary text-decoration-none">Lorem ipsum</a></li>
                          <li className="mb-2"><a href="#" className="link-primary text-decoration-none">Lorem ipsum</a></li>
                          <li className="mb-2"><a href="#" className="link-primary text-decoration-none">Lorem ipsum</a></li>
                      </ul>

                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>

              </Grid.Column>
              
            </Grid.Row>
          </Grid> 
          
      </Segment>
  )
};

export default injectIntl(Search);