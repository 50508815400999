/**
 * Frontpage component.
 * @module components/Frontpage/Frontpage
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { BodyClass, hasBlocksData } from '@plone/volto/helpers';
import { Announcements, Events, News, Slides, Search } from './';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

import { setHeaderVisibility } from '../../actions';


const Frontpage = (props) => {
  const { content } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setHeaderVisibility(true));
    };
  }, []);

  useEffect(() => {
    dispatch(setHeaderVisibility(false));
  }, [dispatch]);

  return (
    <>
      <BodyClass className='frontpage no-inner-header'/>

      <h1 className="visually-hidden"><FormattedMessage id="Alba Synchrotron" defaultMessage="Alba Synchrotron"  /></h1>
      <Slides {...props} />

      <div className="wrapperNewsEvents">
        <Announcements {...props} />
        <News {...props} />
        <Events {...props} />
      </div>
      <Search {...props} />

      {hasBlocksData(content) ? (
        <Container className="userBlocks">
          <RenderBlocks {...props} />
        </Container>
      ): null}

    </>
  );
};


export default Frontpage;
