/**
 * Slides component.
 * @module components/Frontpage/Slides
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Segment, Button } from 'semantic-ui-react';
import { keys, isEmpty } from 'lodash';

import { UniversalLink, Image } from '@plone/volto/components';
import { searchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import { FormattedMessage } from 'react-intl';

import { Carousel } from '../';


const getSlidesPath = function (content) {
  let language = content.language.token
  let path = '/' + content.id + '/home/slides'

  if (language === 'ca')
    path = '/' + content.id + '/portada/slides'
  if (language === 'es')
    path = '/' + content.id + '/portada/slides'

  return path
}


const SLIDE_OPTIONS = {
  loop: true,
  autoplay: true,
  dots: true,
  buttons: true,
  customClass: 'main-slider',
  stopOnMouseEnter: true
}


const ItemView = props => {
  const {item} = props

  return (
      <article className=" slideItem " key={item['@id']}>
          <Image
            item={item}
            alt=""
            className="slideItem__image"
          />
          <div className="slideItem__text">
            <h3 className="slideItem__title">
            {item.title}
              
            </h3>
            {item?.description ? (
              <p className="slideItem__desc mb-0">
                {item.description}
              </p>
            ) : null }
            <p>
              <UniversalLink
                className="slideItem__readmore slideItem__link ui btn btn-white inverted button"
                href={item.remoteUrl}>          
                {item.button_text ? item.button_text : <><FormattedMessage id="readMore" defaultMessage="Read more" /> <span class="visually-hidden">{item.title}</span></> } <FontAwesomeIcon className="ms-3" icon={faArrowRightLong}  />
              </UniversalLink>
            </p>
          </div>
      </article>
  )

};

const Slides = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const slides = useSelector((state) => state.search.subrequests.slides?.items);
  const slidesPath = getSlidesPath(content)

  React.useEffect(() => {
    dispatch(
      searchContent(
        slidesPath,
        {
          portal_type: ['Slide'],
          review_state: 'published',
          sort_on: 'getObjPositionInParent',
          b_size: 8,
          metadata_fields: ['remoteUrl', 'button_text']
        },
        'slides',
      ),
    );
  }, [dispatch]);

  return !isEmpty(slides) ? (
      <Segment id="featuredSection" as="section" basic className="slides slidesWrapper">
        <h2 className="visually-hidden"><FormattedMessage id="featured" defaultMessage="Featured" /></h2>
        <Carousel slides={slides.slice(0, 8)} delayTime={6500} ItemView={ItemView} options={SLIDE_OPTIONS} />

      </Segment>
  ) : (
    <></>
  );
};

export default Slides;
