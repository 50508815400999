export const ProfileSchema = (props) => {
  return {
    title: 'Profiles',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['name', 'description', 'email', 'phone', 'image'],
      },
    ],
    properties: {
      name: {
        title: 'Name',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'text',
      },
      email: {
        title: 'Email',
        widget: 'text',
      },
      phone: {
        title: 'Phone',
        widget: 'text',
      },
      image: {
        title: 'Image',
        widget: 'attachedimage',
      },
    },
    required: ['name', 'description', 'email', 'phone'],
  };
};

export const Schema = (props) => {
  return {
    title: 'Contact Profiles',
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'email', 'description', 'profiles'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      email: {
        title: 'Email',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'text',
      },
      profiles: {
        title: 'Profiles',
        widget: 'object_list',
        schema: ProfileSchema(props),
      },
    },
    required: ['title', 'profiles'],
  };
};
