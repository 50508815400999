import React from 'react';
import config from '@plone/volto/registry';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
} from 'recharts';
import { PieChart, Pie } from 'recharts';
import { BarChart, Bar, Legend } from 'recharts';
import { ComposedChart, Line } from 'recharts';
import { ResponsiveContainer } from 'recharts';
import { Sector } from 'recharts';
import { state } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { Cell } from 'recharts';
import { Grid, Segment } from 'semantic-ui-react';
import { getCharts } from '../../services';
import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  numberUsersTitle: {
    id: "Number of users",
    defaultMessage: "Number of users",
  },
  originTitle: {
    id: "Home institutions' origin of granted academic proposals this year",
    defaultMessage: "Home institutions' origin of granted academic proposals this year",
  },
  originRegionTitle: {
    id: "Home institutions' origin by region",
    defaultMessage: "Home institutions' origin by region",
  },
  industrialRelevance: {
    id: "Industrial relevance of granted academic proposals this year",
    defaultMessage: "Industrial relevance of granted academic proposals this year",
  },
  publicationsTitle: {
    id: "Publications",
    defaultMessage: "Publications",
  },
  researchAreaTitle: {
    id: "Research area of granted academic proposals this year",
    defaultMessage: "Research area of granted academic proposals this year",
  },
});


const Charts = (state) => {
  const COLORS = ['#293E6B', '#B6966A', '#B1ACA1', '#E46D67', '#6A7A9A', '#636463', '#94514D', '#A5A4A4', '#DC832F'];
  const COLORS_RESEARCH = ['#6A7A9A', '#636463', '#94514D', '#A5A4A4', '#DC832F', '#815C5B', '#91A53F', '#274478', '#9D4916', '#E6B029', '#d1a83d'];
  const COLORS_ORIGIN = ['#e6b029', '#293E6B', '#B1ACA1']

  const [userByType, setUserByType] = useState(null);
  const [currentGrantedProposals, setCurrentGrantedProposals] = useState(null);
  const [grantedProposalsbyCCAA, setGrantedProposalsbyCCAA] = useState(null);
  const [publicationsByImpact, setPublicationsByImpact] = useState(null);
  const [grantedByIndustrialRelevance, setGrantedByIndustrialRelevance] = useState(null);
  const [grantedByResearchArea, setGrantedByResearchArea] = useState(null);
  const intl = useIntl();

  const renderCustomizedPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const labelX = cx + (radius + 40) * Math.cos(-midAngle * RADIAN);
    const labelY = cy + (radius + 40) * Math.sin(-midAngle * RADIAN);

    return (
      <text x={labelX} y={labelY} fill="var(--sa-primary" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      // USERS
      try {
        const userByTypeData = await getCharts('https://userofficesystem.cells.es/API/statistic/user_information/');
        setUserByType(userByTypeData);
      } catch {
        setUserByType(null);
      }

      // ORIGIN
      try {
        const grantedProposalsbyCCAData = await getCharts('https://userofficesystem.cells.es/API/statistic/granted_by_region/current/');
        grantedProposalsbyCCAData.sort((a, b) => b.value - a.value)
        setGrantedProposalsbyCCAA(grantedProposalsbyCCAData);
      } catch {
        setGrantedProposalsbyCCAA(null);
      }
      try {
        const currentGrantedProposalsData = await getCharts('https://userofficesystem.cells.es/API/statistic/granted_by_country/current/');
        setCurrentGrantedProposals(currentGrantedProposalsData);
      } catch {
        setCurrentGrantedProposals(null);
      }

      // PUBLICATIONS
      try {
        const publicationsByImpactData = await getCharts('https://userofficesystem.cells.es/API/statistic/publications/');
        setPublicationsByImpact(publicationsByImpactData);
      } catch {
        setPublicationsByImpact(null);
      }
      try {
        const grantedByIndustrialRelevanceData = await getCharts('https://userofficesystem.cells.es/API/statistic/granted_by_industrial_relevance/current/');
        setGrantedByIndustrialRelevance(grantedByIndustrialRelevanceData);
      } catch {
        setGrantedByIndustrialRelevance(null);
      }

      // RESEARCH
      try {
        const grantedByResearchAreaData = await getCharts('https://userofficesystem.cells.es/API/statistic/granted_by_research_area/current/');
        grantedByResearchAreaData.sort((a, b) => b.value - a.value)
        setGrantedByResearchArea(grantedByResearchAreaData);
      } catch {
        setGrantedByResearchArea(null);
      }

    };
    fetchData();
  }, []);

  return (
    <>
      <div className="mainGraphWrapper">
        <div className="graphWrapper">
          <Header as="h3" className="fs-5" ><FormattedMessage id="Number of users" defaultMessage="Number of users" /></Header>
          <ResponsiveContainer width='100%' aspect={16.0 / 7.0}>
            <BarChart
              title={intl.formatMessage(messages.numberUsersTitle)}
              width={1000}
              height={300}
              data={userByType}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="official_users" name="Official Users" stackId="users" fill="#293E6B" />
              <Bar dataKey="education" name="Education" stackId="users" fill="#e6b029" />
              <Bar dataKey="experts_users" name="Experts Users" stackId="users" fill="#B1ACA1" />
              <Bar dataKey="external_access" name="External Access" stackId="users" fill="#e46d67" />
              <Bar dataKey="inhouse_users" name="In-House Users" stackId="users" fill="#9B6A93" />
              <Bar dataKey="industrial_users" name="Industrial Users" stackId="users" fill="#d5da7a" />
              <Bar dataKey="grand_total" name="Grand Total" stackId="users" fill="#637B9C" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className='graphWrapper'>
          <Header as="h3" className="fs-5"><FormattedMessage id="Home institutions' origin of granted academic proposals this year" defaultMessage="Home institutions' origin of granted academic proposals this year" /></Header>
          <Grid className="mb-5" >
            <Grid.Row columns={12}>
              <Grid.Column className="mb-5" mobile={12} tablet={6} computer={4} largeScreen={4} widescreen={4}>
                <ResponsiveContainer width='100%' aspect={4.0 / 3.0}>
                  <PieChart width={400} height={400}>
                    <Pie
                      title={intl.formatMessage(messages.originTitle)}
                      data={currentGrantedProposals}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={90}
                      label={renderCustomizedPieLabel}
                      fill="#8884d8"
                      dataKey="value">
                      {currentGrantedProposals && currentGrantedProposals.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS_ORIGIN[index % COLORS_ORIGIN.length]} />
                      ))}
                    </Pie>
                    <Legend iconType="line" />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={6} computer={8} largeScreen={8} widescreen={4}>
                <ResponsiveContainer width='100%' aspect={9.0 / 9.0}>
                  <BarChart
                    title={intl.formatMessage(messages.originRegionTitle)}
                    layout="vertical"
                    data={grantedProposalsbyCCAA}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 20,
                      bottom: 10,
                    }}
                    height={900}
                    width={900}
                  >
                    <XAxis type="number" hide={true} />
                    <YAxis dataKey="name" type="category" />
                    <Bar dataKey="value" name="Proposals" barSize={15} stackId="value" fill="#293E6B" />
                    <Tooltip cursor={{fill: 'transparent'}} />
                  </BarChart>
                </ResponsiveContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
    
        <div  className="graphWrapper">
          <Header as="h3" className="fs-5"><FormattedMessage id="Industrial relevance of granted academic proposals this year" defaultMessage="Industrial relevance of granted academic proposals this year" /></Header>
          <Grid className="mb-5" >
            <Grid.Row columns={12}>
              <Grid.Column className="mb-5" mobile={12} tablet={6} computer={4} largeScreen={4} widescreen={4}>
                <ResponsiveContainer width='100%' aspect={4.0 / 3.0}>
                  <PieChart width={400} height={300}>
                    <Pie
                      title={intl.formatMessage(messages.industrialRelevance)}
                      data={grantedByIndustrialRelevance}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={90}
                      label={renderCustomizedPieLabel}
                      fill="#293E6B"
                      dataKey="value">
                      {grantedByIndustrialRelevance && grantedByIndustrialRelevance.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS_ORIGIN[index % COLORS_ORIGIN.length]} />
                      ))}
                    </Pie>
                    <Legend iconType="line" />
                    <Tooltip />                    
                  </PieChart>
                </ResponsiveContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div className='graphWrapper'>
          <Grid className="mb-5 " >
            <Grid.Row columns={12}>
              <Grid.Column className="mb-5" mobile={12} tablet={6} computer={4} largeScreen={4} widescreen={4}>
                <Header as="h3" className="fs-5"><FormattedMessage id="Research area of granted academic proposals this year" defaultMessage="Research area of granted academic proposals this year" /></Header>
                <PieChart width={400} height={300}>
                  <Pie
                    title={intl.formatMessage(messages.researchAreaTitle)}
                    data={grantedByResearchArea}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={90}
                    label={renderCustomizedPieLabel}
                    fill="#B1ACA1"
                    dataKey="value">
                    {grantedByResearchArea && grantedByResearchArea.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS_RESEARCH[index % COLORS_RESEARCH.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Grid.Column>
              <Grid.Column mobile={12} tablet={6} computer={8} largeScreen={8} widescreen={4}>
                <dl className='researchList fs-sm mt-lg-5' >
                  {grantedByResearchArea && grantedByResearchArea.map((item) => (
                    <React.Fragment key={item.id}  >
                      <div className="researchList__item">
                        <dt className='researchList__term'>{item.name}</dt>
                        <dd className='researchList__definition ms-0 me-3 fw-bold'>{item.value}</dd>
                      </div>
                    </React.Fragment>
                  ))}
                </dl>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        <div  className="graphWrapper">
          <Header as="h3" className="fs-5"><FormattedMessage id="Publications" defaultMessage="Publications" /></Header>
          <Grid className="mb-5" >
            <Grid.Row columns={12}>
              <Grid.Column mobile={12} tablet={12} computer={12} largeScreen={12} widescreen={12}> 
                <ResponsiveContainer width='100%' aspect={16.0 / 5.0}>
                  <BarChart
                    title={intl.formatMessage(messages.publicationsTitle)}
                    width={1600}
                    height={500}
                    data={publicationsByImpact}
                    margin={{
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total" name="Total number of publications" stackId="total" fill="#293E6B" />
                    <Bar dataKey="with_impact_factor" name="Number of publications with i.f." stackId="with_impact_factor" fill="#e6b029" />
                    <Bar dataKey="with_high_impact_factor" name="Publications with i.f.>7" stackId="with_high_impact_factor" fill="#B1ACA1" />
                  </BarChart>
                </ResponsiveContainer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

      </div>
    </>
  );
};

export default Charts;
