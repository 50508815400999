/**
 * Header actions.
 * @module actions/hear/hear
 */

import { SET_HEADER_VISIBILITY, SET_HEADER_TITLE, SET_HEADER_DESCRIPTION, SET_HEADER_CONTENT } from '../../constants/ActionTypes';


/**
 * Set header visibility function.
 * @function setHeaderVisibility
 * @param {Object} Header info.
 * @returns {Object} Header action.
 */
export function setHeaderVisibility(value) {
  return {
    type: SET_HEADER_VISIBILITY,
    value,
  };
}

/**
 * Set header title function.
 * @function setHeaderTitle
 * @param {Object} Header info.
 * @returns {Object} Header action.
 */
export function setHeaderTitle(value) {
  return {
    type: SET_HEADER_TITLE,
    value,
  };
}

/**
 * Set header description function.
 * @function setHeaderDescription
 * @param {Object} Header info.
 * @returns {Object} Header action.
 */
export function setHeaderDescription(value) {
  return {
    type: SET_HEADER_DESCRIPTION,
    value,
  };
}


/**
 * Set header content function.
 * @function setHeaderCotnent
 * @param {Object} Header info.
 * @returns {Object} Header action.
 */
export function setHeaderContent(value) {
  return {
    type: SET_HEADER_CONTENT,
    value,
  };
}
