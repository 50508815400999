import React from 'react';
import { Segment, Card, Grid, Header } from 'semantic-ui-react';
import { getFieldURL } from './utils';
import { getScaleUrl } from './utils';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';


const View = (props) => {
  const { data, mode } = props;
  return (
      <Segment basic className="contactProfile">
        {data.title && <Header as="h2" className="contactProfile__title">{data.title}</Header>}
        {data.email && <a className="contactProfile__email" href={"mailto:" + data.email}>{data.email}</a>}
        {data.description && <p className="contactProfile__desc">{data.description}</p>}
        {data.profiles && (
          <Grid columns={12}>
            {data.profiles.map((profile, index) => (
            <Grid.Column key={index} mobile={12} tablet={6} computer={4}>
              <Card className={"contactProfileItem " + ( (data.variation!=='default') ? " contactProfileItem--imageTop " : "" ) } fluid>
                {profile.image && (
                  <figure className='contactProfileItem__figure'>
                    <img className='contactProfileItem__figure__image'
                      src={
                        getScaleUrl(getFieldURL(profile.image), 'preview') ||
                        DefaultImageSVG
                      }
                      alt={profile.name ? profile.name : ''}
                      />
                  </figure>
                )}
                <Card.Content className="contactProfileItem__text">
                  {profile.name &&  <Card.Header className={'contactProfileItem__title' + (profile.description ? ' contactProfileItem__title--hasDesc' : ' contactProfileItem__title--noDesc')}  as="h3">{profile.name}</Card.Header>}
                  {profile.description && <Card.Meta className='contactProfileItem__desc'  as="p">{profile.description}</Card.Meta>}
                  { (profile.phone || profile.email) && (
                    <Card.Description as="p">
                      {profile.phone && <a className='contactProfileItem__phone' href={"tel:" + profile.phone.replace(/\s/g, "")}>{profile.phone}</a>}
                      {profile.email && <a className='contactProfileItem__email' href={"mailto:" + profile.email}>{profile.email}</a>}                      
                    </Card.Description>
                  )}
                </Card.Content>
              </Card>
            </Grid.Column> 
            ))}
          </Grid>
        )}
      </Segment>
  );
};

export default View;
