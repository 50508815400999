/**
 * PublicationsListing component.
 * @module components/Publications/PublicationsListing
 */

import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Container, Segment, List, Label, Grid, Card, Form, Loader } from 'semantic-ui-react';
import { BodyClass, Helmet, hasBlocksData } from '@plone/volto/helpers';
import { UniversalLink, FormattedDate, Pagination, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { keys, isEmpty, map } from 'lodash';
import { useDebounce } from "use-debounce";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { getPublications } from '../../services/getPublications';


const messages = defineMessages({
  labelText: {
    id: 'Text',
    defaultMessage: 'Text',
  },
  placeholderText: {
    id: 'What are you looking for?',
    defaultMessage: 'What are you looking for?',
  },
  defaultOptionYears: {
    id: 'All years',
    defaultMessage: 'All years',
  },
  defaultOptionPublicationTypes: {
    id: 'All publication types',
    defaultMessage: 'All publication types',
  },
  defaultOptionResearchTypes: {
    id: 'All types',
    defaultMessage: 'All types',
  },
  defaultOptionBlCodes: {
    id: 'All instruments',
    defaultMessage: 'All instruments',
  },
  labelPublicationType: {
    id: 'Publication Type',
    defaultMessage: 'Publication Type',
  },
  labelResearchType: {
    id: 'Research Type',
    defaultMessage: 'Research type',
  },
  labelblCode: {
    id: 'Instruments',
    defaultMessage: 'Instruments',
  },
  labelYear: {
    id: 'Year',
    defaultMessage: 'Year',
  },
  placeholderAllYears: {
    id: 'All years',
    defaultMessage: 'All years',
  },
  placeholderAllPublicationType: {
    id: 'All publication types',
    defaultMessage: 'All publication types',
  },
  placeholderAllResearchTypes: {
    id: 'All types',
    defaultMessage: 'All types',
  },
  placeholderAllblCodes: {
    id: 'All instruments',
    defaultMessage: 'All instruments',
  },
});


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};


const PublicationsListing = (props) => {
  const { content } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  
  const [loader, setLoader] = useState(true);    
  const [publications, setPublications] = useState([]);
  const [activePublications, setActivePublications] = useState([]);
  const [total, setTotal] = useState(0);
  
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  
  const [searchableTextInput, setSearchableText] = useState('');
  const [searchableText] = useDebounce(searchableTextInput, 500);
  const [search, setSearch] = useState(0);

  const [year, setYear] = useState('');
  const [researchType, setResearchType] = useState('');
  const [publicationType, setPublicationType] = useState('');
  const [blCode, setblCode] = useState('');

  const [yearsVocabulary, setYearsVocabulary] = useState('');
  const [researchTypesVocabulary, setResearchTypesVocabulary] = useState('');
  const [publicationTypesVocabulary, setPublicationTypesVocabulary] = useState('');
  const [blCodesVocabulary, setBlCodesVocabulary] = useState('');

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const getBlCodesVocabulary = (publications) => {
    let blCodesVocabulary = []
    let filteredPublications = publications.filter((item) => item.BLlist !== 'None' && item.BLlist !== 'NOBL@ALBA')

    filteredPublications.forEach((publication) => {
      let blCodes = publication.BLlist.split(',')
      blCodes.forEach((blCode, index) => {
        blCodesVocabulary.push(blCode.trim() + '-' + publication.BLNamelist.split(',')[index].trim())
      })
    });

    blCodesVocabulary = new Set(blCodesVocabulary)
    return blCodesVocabulary
  };


  useEffect(() => {
    let mounted = true;
    getPublications().then((items) => {
      if (mounted) {
        setPublications(items);
        setLoader(false)
      }
    });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (query.get('instrument')) {
      setblCode(query.get('instrument'))
    }
  }, []);

  useEffect(() => {
    setYearsVocabulary(
      [
        {value: '', label: intl.formatMessage(messages.defaultOptionYears)},
        ...[
          ...new Set(publications.map((item) => item.year))
        ]
        .sort().reverse()
        .map((item) => {return {value: item, label: item}})
      ]
    );
    setResearchTypesVocabulary(
      [
        {value: '', label: intl.formatMessage(messages.defaultOptionResearchTypes)},
        ...[
          ...new Set(publications.map((item) => item.researchType))
        ]
        .sort()
        .map((item) => {return {value: item, label: item}})
      ]
    );
    setPublicationTypesVocabulary(
      [
        {value: '', label: intl.formatMessage(messages.defaultOptionPublicationTypes)},
        ...[
          ...new Set(publications.map((item) => item.publicationType))
        ]
        .sort()
        .map((item) => {return {value: item, label: item}})
      ]
    );

    setBlCodesVocabulary(
      [
        {value: '', label: intl.formatMessage(messages.defaultOptionBlCodes)},
        ...[
          ...getBlCodesVocabulary(publications)
        ]
        .sort()
        .map((item) => {return {value: item.split('-')[0], label: item}})
      ]
    );

    setTotal(publications.length);
  }, [publications]);


  useEffect(() => {
    if (!publications) {
      return;
    }

    let activePublications = publications;

    if (year) {
      activePublications = activePublications.filter((item) =>
        item.year === year
      );
    }
  
    if (publicationType) {
      activePublications = activePublications.filter((item) =>
        item.publicationType === publicationType
      );
    }
  
    if (blCode) {
      activePublications = activePublications.filter((item) =>
        item.BLlist.includes(blCode)
      );
    }
  
    if (researchType) {
      activePublications = activePublications.filter((item) =>
        item.researchType === researchType
      );
    }

    if (searchableText) {
      activePublications = activePublications.filter((item) =>
        item.Title && item.Title.toLowerCase().includes(searchableText.toLowerCase())
      );
    }

    setTotal(activePublications.length);
    activePublications = activePublications.slice(currentPage * pageSize, (currentPage * pageSize) + pageSize)
    setActivePublications(activePublications);

  }, [publications, currentPage, pageSize, searchableText, year, publicationType, blCode, researchType]);

  useEffect(() => {
    const searchForm = document.querySelector('.searchList');
    const searchFormPos = searchForm ? searchForm.offsetTop : 0;
    let scrollPos = window.scrollY;
    if (scrollPos > searchFormPos && searchForm) {
      window.scrollTo({top: searchFormPos})
    }

  }, [currentPage]);


  const onChangePage = (event) => {
    event.preventDefault();
    setCurrentPage(parseInt(event.target.text) - 1);
  };
  const onChangePageSize = (event) => {
    event.preventDefault();
    setPageSize(parseInt(event.target.text));
    setCurrentPage(0);
  };

  const onChangeSearchableText = (event, data) => {
    event.preventDefault();
    setSearchableText(data.value);
    setCurrentPage(0);
  };

  const onChangePublicationType = (event, data) => {
    event.preventDefault();
    setPublicationType(data.value);
    setCurrentPage(0);
  };

  const onChangeResearchType = (event, data) => {
    event.preventDefault();
    setResearchType(data.value);
    setCurrentPage(0);
  };

  const onChangeblCode = (event, data) => {
    event.preventDefault();
    updateQuery('instrument', data.value)
    setblCode(data.value);
    setCurrentPage(0);
  };

  const onChangeYear = (event, data) => {
    event.preventDefault();
    setYear(data.value);
    setCurrentPage(0);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSearch(search + 1);
    setCurrentPage(0);
  };

  const updateQuery = (param, value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(param, value);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <>
      <BodyClass className='publicationslisting headerNegativeBottom' />

      {hasBlocksData(content) ? (
        <Container className="userBlocks">
          <RenderBlocks {...props} />
        </Container>
      ) : null}

      <Container fluid className="pb-4  d-print-none">
        <Form className="searchList" onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              id="textInputId"
              fluid
              label={intl.formatMessage(messages.labelText)}
              focus
              name="searchableText"
              value={searchableTextInput}
              onChange={onChangeSearchableText}
              placeholder={intl.formatMessage(messages.placeholderText)}
              type="text"
            />
            <Form.Select
              id="yearSelectId"
              fluid
              label={intl.formatMessage(messages.labelYear)}
              options={map(yearsVocabulary, (item, index) => {
                return {
                  key: index,
                  value: item.value,
                  text: item.label,
                };
              })}
              name="year"
              value={year}
              onChange={onChangeYear}
              placeholder={intl.formatMessage(messages.placeholderAllYears)}
            />
            <Form.Select
              id="publicationTypeSelectId"
              fluid
              label={intl.formatMessage(messages.labelPublicationType)}
              options={map(publicationTypesVocabulary, (item, index) => {
                return {
                  key: index,
                  value: item.value,
                  text: item.label,
                };
              })}
              name="publicationType"
              value={publicationType}
              onChange={onChangePublicationType}
              placeholder={intl.formatMessage(messages.placeholderAllPublicationType)}
            />
            <Form.Select
              id="blCodeSelectId"
              fluid
              label={intl.formatMessage(messages.labelblCode)}
              options={map(blCodesVocabulary, (item, index) => {
                return {
                  key: index,
                  value: item.value,
                  text: item.label,
                };
              })}
              name="blCode"
              value={blCode}
              onChange={onChangeblCode}
              placeholder={intl.formatMessage(messages.placeholderAllblCodes)}
            />
            <Form.Select
              id="researchTypeSelectId"
              fluid
              label={intl.formatMessage(messages.labelResearchType)}
              options={map(researchTypesVocabulary, (item, index) => {
                return {
                  key: index,
                  value: item.value,
                  text: item.label,
                }
              })}
              name="researchType"
              value={researchType}
              onChange={onChangeResearchType}
              placeholder={intl.formatMessage(messages.placeholderAllResearchTypes)}
            />
          </Form.Group>
          <Form.Button className="searchList__send searchList__btn" primary><FormattedMessage id="Search" defaultMessage="Search" /></Form.Button>
        </Form>
      </Container>

      <Segment basic>
        <Container>
          <p className='searchList__result border-bottom border-top py-2 fs-sm'><FormattedMessage id="Items found" defaultMessage="Items found" />: <strong>{total}</strong></p>          
        </Container>
        
        {!isEmpty(activePublications) ? (
          <Container className="news">
            {activePublications.map((item, id) => (
              <Card as="article" fluid key={id} className="publicationItem mb-5">
                <Card.Header as="h2" className="publicationItem__title">
                  <UniversalLink
                    className="publicationItem__link stretched-link"
                    href={flattenToAppURL(item.externalUrl)}
                  >
                    {item.Title}
                  </UniversalLink>
                </Card.Header>
                <Card.Content>
                  {(item.authorsList && item.authorsList !== 'None') && (
                    <p className="publicationItem__desc">{item.authorsList}</p>
                  )}
                  {(item.BLlist && item.BLlist !== 'None' && item.BLNamelist) && (
                    <>
                    {item.BLlist.split(',').map((blCode, id) => (
                      <p className="publicationItem__type" key={id}>
                        <span className="">{blCode} - {item.BLNamelist.split(',')[id]}</span>
                      </p>
                    ))}
                    </>
                  )}
                  {(item.reference || item.doi) && (
                    <p className="publicationItem__subText">
                      <span>{item.reference}</span> <span>{item.doi}</span>
                    </p>
                  )}
                  <span className="squareMore" aria-hidden="true"><FontAwesomeIcon icon={faArrowRight} /></span>
                </Card.Content>
              </Card>
            ))}

            {total > pageSize && (
              <nav className="pagination">
                <Pagination
                  current={currentPage}
                  total={Math.ceil(total / pageSize)}
                  onChangePage={onChangePage}
                  onChangePageSize={onChangePageSize}
                />
              </nav>
            )}
          </Container>
        ) : (
          <Container className="news">
            {loader ? (
                <Loader active inline='centered'>
                  <FormattedMessage id="Loading" defaultMessage="Loading" />
                </Loader>
            ) : (
              <FormattedMessage id="No publications found" defaultMessage="No publications found" />
            )}
          </Container>
        )}
      </Segment>
    </>
  );
};

export default PublicationsListing;
