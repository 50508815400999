/**
 * News component.
 * @module components/Frontpage/News
 */

import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Header, Segment, Label, Card, Grid } from 'semantic-ui-react';
import { keys, isEmpty, map } from 'lodash';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Carousel } from '../';


const News = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const vocabularyNewsitemTypes = 'product.alba.vocabularies.NewsitemTypes';
  const itemsNewsitemTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyNewsitemTypes] && state.vocabularies[vocabularyNewsitemTypes].items
        ? state.vocabularies[vocabularyNewsitemTypes].items
        : [],
    shallowEqual,
  );
  const getNewsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id


    if (language === 'ca')
      path = '/' + language + '/public/noticies'
    if (language === 'es')
      path = '/' + language + '/publico/noticias'
    if (language === 'en')
      path = '/' + language + '/public/news'

    return flattenToAppURL(path)
  }
  const getNewsURLView = function (content) {
    let language = content.language.token
    let path = content['@id']

    if (language === 'ca')
      path = content['@id'] + '/noticies'
    if (language === 'es')
      path = content['@id'] + '/noticias'
    if (language === 'en')
      path = content['@id'] + '/news'

    return flattenToAppURL(path);
  }
  const newsURL = getNewsURL(content);
  const newsURLView = getNewsURLView(content);

  const ItemView = (props) => {
    const { item } = props;

    const getNewsItemType = function (typeN) {
      if (!itemsNewsitemTypes) {
        return typeN;
      }
      for (let item of itemsNewsitemTypes) {
        if (item.value === typeN) return item.label;
      }
      return typeN;
    };

    return (
      <Card fluid as="figure" className={'newsItem ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')} >
        <div className="ui image newsItem__img__wrapper">
          <Image 
            className="newsItem__img"
            item={item}
            loading='lazy'
            alt=""
            sizes="(max-width:575px) 100vw, 24rem"
          />
          {item?.newsitem_types ? (
            <Label.Group className="subjects newsItem__type">
              {map(item.newsitem_types, (item, index) => (
                <Label key={index}>{getNewsItemType(item)}</Label>
              ))}
            </Label.Group>
          ) : null}
        </div>
        <Card.Content as="figcaption" className="newsItem__text">                            
          <Card.Meta>
            <FormattedDate date={item.effective} className="newsItem__date "
              format={{
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }}
            />
          </Card.Meta>
          <Card.Header>
            <UniversalLink
              className="newsItem__link newsItem__title stretched-link" 
              href={flattenToAppURL(item['@id'])}>
              {item.title}
            </UniversalLink>
          </Card.Header>
        </Card.Content>
      </Card>
    );
  };

  const SLIDE_OPTIONS = {
    loop: false,
    customClass: 'related-events-slider',
    align: 'start',
    buttons: true,
    dots: false,
  };

  useEffect(() => {
    dispatch(
      searchContent(
        newsURL,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          b_size: 6,
          newsitem_types: 2,
          sort_on: 'effective',
          sort_order: 'reverse',
          metadata_fields: ['effective', 'Subject', 'newsitem_types', 'hasPreviewImage']
        },
        'news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyNewsitemTypes, lang: content.language.token }));
  }, [dispatch]);

  return !isEmpty(news) ? (
      <Segment as="section" basic id="homeNews" className="news py-5 px-0 newsIndustrySectiion">
          <Header as="h2" size="large" className="mt-0 text-primary"><FormattedMessage id="News" defaultMessage="News"/></Header>
          <Carousel slides={news} ItemView={ItemView} options={SLIDE_OPTIONS} />
          <p className="text-center pt-4">
            <UniversalLink href={newsURLView} className="ui primary button mt-lg-auto mb-4">
              <FormattedMessage id="View more" defaultMessage="View more"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
            </UniversalLink>
          </p>         
      </Segment>

  ) : (
    <>
    </>
  );
};

export default News;
